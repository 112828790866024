<!--
 * @Author: LegerVeilchen LegerVeilchen@foxmail.com
 * @Date: 2022-05-30 18:11:04
 * @LastEditors: LegerVeilchen LegerVeilchen@foxmail.com
 * @LastEditTime: 2022-06-14 18:23:04
 * @FilePath: \loan-web\src\views\login\login.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="body">
    <Nav></Nav>
    <!-- 搜索 -->
    <div class="success">
      <div class="heart">
        <!-- <h2 class="title">测算结果已发送至您的邮箱</h2> -->
        <el-result icon="success" title="提交成功">
          <template slot="extra">
            <!-- <el-link @click="jump" type="primary">返回</el-link> -->
          </template>
        </el-result>
      </div>
    </div>
    <!-- 联系我们 -->
    <!-- <Consultation></Consultation> -->
    <!-- 我们的信息 -->
    <!-- <Bottom></Bottom> -->
  </div>
</template>

<script>
import Nav from "@/components/Nav/Nav.vue";
import Consultation from "@/components/Consultation/Consultation.vue";
import Bottom from "@/components/Bottom/Bottom.vue";
export default {
  data() {
    return {
      time: 3,
      timer: "",
    };
  },
  created() {

  },
  methods: {

    jump() {
      this.$router.replace("/loanPrincipal");
    },
  },
  components: {
    Consultation,
    Nav,
    Bottom,
  },
};
</script>

<style lang="scss" scoped>
@import "@/baseScss/baseScss.scss";

.body {
  .success {
    @include column;
    justify-content: center;
    min-height: 70vh;

    .title {
      @include title;
      font-weight: 400;
      font-size: 28px;
      margin-bottom: 20px;
    }
  }
}

::v-deep .popper__arrow {
  display: none !important;
}

::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff !important;
  color: $color;
}

::v-deep .el-form-item__label {
  color: rgb(146, 148, 149);
}

::v-deep .el-input__inner:focus {
  border-color: #333;
}
</style>
