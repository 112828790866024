import request from "@/utils/request";

// 获取新增资产类型
const getPawnType = (data) => {
    return request({
        url: "loan/getPawnType",
        method: "post",
        data,
    });
};
// 获取贷款主体类型
const getEntityTypes = (data) => {
    return request({
        url: "loan/getEntityTypes",
        method: "post",
        data,
    });
};
// 获取贷款类型
const getLoanPurposes = (data) => {
    return request({
        url: "loan/getLoanPurposes",
        method: "post",
        data,
    });
};

// 立即评估
const getLoanResult = (data) => {
    return request({
        url: "loan/getLoanResult",
        method: "post",
        data,
    });
};

// 获取详情
const getEstimateDetail = (data) => {
    return request({
        url: "loan/getEstimateDetail",
        method: "post",
        data,
    });
};
// 获取列表
const loanEvaluationList = (data) => {
    return request({
        url: "loanEvaluationList",
        method: "post",
        data,
    });
};
// 获取详情
const entityCombination = (data) => {
    return request({
        url: "entityCombination",
        method: "post",
        data,
    });
};

// 获取详情
const getIncomeList = (data) => {
    return request({
        url: "loan/getIncomeList",
        method: "post",
        data,
    });
};

// 获取下载文件
const exportLoanRecord = (data) => {
    return request({
        url: "loan/exportLoanRecord",
        method: "post",
        data,
    });
};

// 获取留言记录
const getLeaveRecords = (data) => {
    return request({
        url: "getLeaveRecords",
        method: "post",
        data,
    });
};

// 提交留言
const leaveMsgs = (data) => {
    return request({
        url: "leaveMsgs",
        method: "post",
        data,
    });
};
export {
    getEntityTypes,
	getLoanPurposes,
	getLoanResult,
	getEstimateDetail,
	loanEvaluationList,
	entityCombination,
	getPawnType,
	getIncomeList,
	exportLoanRecord,
	getLeaveRecords,
	leaveMsgs
}