<template>
	<div>
		<el-dialog :title="QWER('留言板')" :visible.sync="dialogVisible" :show-close="true" :close-on-click-modal='false'
			width="1300px" style="border-radius: 20px;margin-top: -8vh">

			<div style="display: flex" class="search">
				<el-button type="primary" @click="close">{{QWER('关闭')}}</el-button>
				<el-button type="primary" @click="openAdd">{{QWER('提问')}}</el-button>
        <div style="flex: 1"></div>
        <el-input
            placeholder="Enter"
            v-model="keyword"
            prefix-icon="el-icon-search"
            @change="changeKey"
            clearable>
        </el-input>

      </div>
			<el-table :data="tabledata" style="width: 100%;margin-top: 20px;"  height="550">
				<el-table-column :label="QWER('序号')" type="index">
				</el-table-column>
				<el-table-column prop="title" :label="QWER('标题')" >
          <template #default="{ row }">
            <div class="text-ellipsis">{{row.title}}</div>
          </template>
				</el-table-column>
				<el-table-column prop="contents" :label="QWER('内容')">
          <template #default="{ row }">
						<div class="text-ellipsis">{{row.contents}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="annexs" :label="QWER('图片')">
					<template #default="{ row }">
						<div style="display: flex;">
							<el-image 
							    style="width: 60px; height: 60px;margin-right: 8px;border-radius: 4px;"
							    :src="item" 
							    :preview-src-list="[item]" v-for='(item,index) in getImageList(row.annexs)'>
							  </el-image>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="created_at" :label="QWER('提交时间')" width="180">
				</el-table-column>
				<el-table-column prop="status" :label="QWER('回复状态')" width="100">
					<template #default="{ row }">
						<div style="color: #FF3737;" v-if="row.status==0"> {{QWER('待回复')}}</div>
						<div style="color: #28C445;" v-if="row.status==1"> {{QWER('已回复')}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="QWER('操作')" width="100">
					<template #default="{ row }">
						<div style="color: #DF7C2B;cursor: pointer;" @click="showDetail(row)"> {{QWER('查看详情')}}</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right;padding-top: 10px;">
				<el-pagination @current-change="changePage" background layout="prev, pager, next" :current-page="page" :total="total">
				</el-pagination>
			</div>
		</el-dialog>
		<addAsk ref='addAsk' @success="updateData"></addAsk>
    <askDetail ref="askDetail"></askDetail>
    <ask-login ref="asklogin" @success="loginSuccess"></ask-login>
	</div>

</template>

<script>
	import {
		getLeaveRecords
	} from "@/api/loans/loans"
	import addAsk from './addAsk.vue'
  import askDetail from "@/views/ask/askDetail.vue";
  import askLogin from "./addLogin.vue";
  import {getToken} from "@/utils/myAuth";
	export default {
		components: {
			addAsk,askDetail,askLogin
		},
		data() {
			return {
				dialogVisible: false,
				page: 1,
        keyword:'',
				tabledata: [],
				total: 1,
			};
		},
		created() {

		},
		methods: {
			QWER(message) {
				let temp = this.$t(message)
				return temp
			},
			open() {
				this.tabledata = []
				const token = getToken()
				console.log(token)
				this.dialogVisible = true
				if(token){
				  this.page = 1
				  this.getData()
				}else{
				  this.dialogVisible = false
				  this.$refs.asklogin.open()
				}
			},
			  changeKey(){
				this.page = 1
				this.getData()
			  },
			  loginSuccess(){
				this.dialogVisible = true
				this.page = 1
				this.getData()
			  },
			  updateData(){
				this.page = 1
				this.getData()
			  },
			openAdd() {
				this.$refs.addAsk.open()
			},
			close(){
				this.dialogVisible = false
			},
			getImageList(item){
				if(item){
					return item.split(',')
				}else{
					return []
				}
			},
			getData() {
        const loading= this.$loading()
				getLeaveRecords({
          parent_id:0,
					page: this.page,
          pageSize:10,
          keyword:this.keyword,
				}).then(res => {
					console.log(res)
          loading.close()
					if (res.code == 200) {
						this.tabledata = res.data.data
						this.total = res.data.total
					} else {

					}
				}).catch(err=>{
          loading.close()
        })
			},
      changePage(page){
        this.page=page
        this.getData()
      },
			showDetail(row){
				this.$refs.askDetail.open(row)
			}
		},
	};
</script>

<style lang="scss" scoped>
.search{
  ::v-deep{
    .el-input{
      width: 260px;
      padding-left: 32px;
      padding-right: 32px;
      border-radius: 30px;
      border: 1px solid #EEEEEE;
      background-color: #EEEEEE;
      input{
        border: 0px;
        background-color: #EEEEEE;
        color: #000000;
      }
      //input::-webkit-input-placeholder {
      //  color: #000000; /* 指定颜色 */
      //}
    }
  }
}
	::v-deep {
		.el-dialog__title {
			font-weight: bold;
			font-size: 30px;
			color: #000000;
			line-height: 20px;
		}

		.el-dialog__headerbtn .el-dialog__close {
			font-size: 24px;
		}

		.el-table th.el-table__cell {
			background-color: #000000;
			color: #fff;
		}

	}

  .text-ellipsis {
    //文本超出采用省略号
    display: -webkit-box;              /* 必须结合 display: -webkit-box; 用于多行文本省略 */
    -webkit-box-orient: vertical;      /* 设置盒子模型的方向为垂直 */
    -webkit-line-clamp: 2;             /* 显示的行数，超出的部分会被截断 */
    overflow: hidden;                  /* 隐藏溢出的内容 */
    text-overflow: ellipsis;           /* 使用省略号表示溢出的文本 */
  }
</style>