/*
 * @Author: your name
 * @Date: 2022-04-12 10:33:56
 * @LastEditTime: 2022-05-31 16:32:23
 * @LastEditors: LegerVeilchen LegerVeilchen@foxmail.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \shoptour-admin-vue\src\utils\method.js
 */
// import { getInitMenus } from "./api/Auth/index.js";
import router from "@/router/index";
// import store from "./store/index.js";
// 跳转
function jump(to) {
  this.$router.push(to);
}
// 接口调用后消息提示
function msg(msg, type) {
  this.$message({
    message: msg,
    customClass: "message",
    type: type ? type : "success",
  });
}
// 消息确认框
function msgBox(option, callback, cancel) {
  // console.log(callback, cancel);
  this.$confirm(option.text, option.title, {
    confirmButtonText: option.suc ? option.suc : "确定",
    cancelButtonText: option.can ? option.can : "取消",
    type: option.type ? option.type : "warning",
  })
    .then(() => {
      callback ? callback() : "";
    })
    .catch(() => {
      console.log("cancel");
      cancel ? cancel() : "";
    });
}
// 加载框
function onLoad(text, icon, color) {
  this.$loading({
    lock: true,
    text: text ? text : "加载中",
    spinner: icon ? icon : "el-icon-loading",
    background: color ? color : "rgba(0, 0, 0, 0.7)",
  });
}
// 关闭加载框
function close() {
  this.$loading().close();
}
// 转换日期格式为 yyyy-MM-dd
function convertTime(time) {
  const timer = `${time.getFullYear()}-${
    time.getMonth() + 1 < 10 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1
  }-${time.getDate() < 10 ? "0" + time.getDate() : time.getDate()}`;
  return timer;
}
// 转化日期格式为 MM月dd日
function getDates(time) {
  const timer = `${
    time.getMonth() + 1 < 10 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1
  }月${time.getDate() < 10 ? "0" + time.getDate() : time.getDate()}日`;
  return timer;
}
// 窗口提示
function befClose(done) {
  this.$confirm("确认关闭？")
    .then(() => {
      done();
    })
    .catch(() => {});
}
function getTime(time) {
  const timer = `${
    time.getHours() < 10 ? "0" + time.getHours() : time.getHours()
  }:${time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()}:${
    time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds()
  }`;
  return timer;
}
// 获取季度
function getQuarter(timer) {
  let month = timer.getMonth() + 1; //getMonth返回0-11
  //下面是几个if
  if (month >= 1 && month <= 3) {
    return 1;
  } else if (month >= 4 && month <= 6) {
    return 2;
  } else if (month >= 7 && month <= 9) {
    return 3;
  } else {
    return 4;
  }
}
// 获取季度开始和结束
function getQuartorStartDate(year, quarter) {
  let startMonth = 1;
  if (quarter == 1) {
    startMonth = 1;
  } else if (quarter == 2) {
    startMonth = 4;
  } else if (quarter == 3) {
    startMonth = 7;
  } else if (quarter == 4) {
    startMonth = 10;
  }
  let endMonth = startMonth + 2;
  if (quarter == 0) {
    endMonth = 12;
  }
  const startDate = year + "-" + this.$formatDate(startMonth) + "-01";
  const endDate =
    year +
    "-" +
    this.$formatDate(endMonth) +
    "-" +
    new Date(year, endMonth, 0).getDate();
  console.log(startDate, endDate);
  return { startDate, endDate };
}
function formatDate(value) {
  if (value < 10) {
    value = "0" + value;
  }
  return value;
}
export {
  jump,
  msg,
  onLoad,
  close,
  convertTime,
  getDates,
  getTime,
  msgBox,
  getQuarter,
  getQuartorStartDate,
  formatDate,
  befClose,
};
