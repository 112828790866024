<template>
  <div class="body">
    <Nav now="2"></Nav>
    <!-- 搜索 -->
    <div class="login">
      <div class="heart">
        <!-- 表单 -->
        <el-form
          label-position="top"
          ref="sign"
          :model="sign"
          :rules="signRuler"
          label-width="80px"
        >
          <h4 class="title">Retrieve Password</h4>
          <p class="desc" v-if="userInfo">
            We will send the verification code to: {{ userInfo.email }} Please
            enter the verification code you received
          </p>
          <!-- <p class="desc">
        我们将验证码发送至邮箱：{{ userInfo.email }} 请输入您收到的验证码
      </p> -->
          <el-form-item prop="resetPhone" v-if="!userInfo">
            <el-input
              placeholder="E-mail"
              v-model="sign.resetPhone"
              type="text"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="int-code">
              <el-input
                placeholder="6-digit Code"
                v-model="sign.code"
                type="number"
                maxlength="6"
                oninput="if(value.length>6)value=value.slice(0,6)"
              ></el-input>
              <div class="get-code">
                <el-button
                  type="primary"
                  size="mini"
                  @click="sendResetCode"
                  :disabled="sendResetTime ? true : false"
                  >Send Code<span v-show="sendResetTime">{{
                    `(${sendResetTime})`
                  }}</span></el-button
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item label="New Password" prop="resetPassword">
            <el-input
              placeholder="New Password"
              show-password
              v-model="sign.resetPassword"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- 验证码 -->
        <div class="operate">
          <div class="phone">
            <div
              :class="loading ? 'reset-btn btn loading' : 'reset-btn btn'"
              @click="changePassword"
            >
              <span style="color: #fff">Verify Code</span>
              <i class="el-icon-loading" v-if="loading"></i>
            </div>
            <div>
              <p class="desc">
                If you do not receive our verification code, please check whether the email is blocked.
              </p>
<!--              <p class="again" @click="sendResetCode" v-if="userInfo">-->
<!--                Click send verification code<span v-show="sendResetTime">{{-->
<!--                  `(${sendResetTime})`-->
<!--                }}</span>-->
<!--                >-->
<!--              </p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <Consultation></Consultation>
    <!-- 我们的信息 -->
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/Nav/Nav.vue";
import Consultation from "@/components/Consultation/Consultation.vue";
import Bottom from "@/components/Bottom/Bottom.vue";
import { smsCode } from "@/api/login/login.js";
import { passwordChange } from "@/api/setting/setting.js";
import { removeUserInfo, removeToken } from "@/utils/myAuth";
export default {
  data() {
    var emailFun = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('The mailbox cannot be empty'));
      }

      setTimeout(() => {
        let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
        if (!reg.test(value)) {
          callback(new Error('Please enter the correct mailbox'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      sign: {
        userName: "",
        passWord: "",
		
        registerPassWord: "",
        resetPassword: "",
        PhoneNumber: "",
        code: "",
        name: "",
        surname: "",
        phone: "",
        loginCode: "",
        resetPhone: "",
      },
      signRuler: {
        userName: [
          {
            required: true,
            message: "Please Enter User Name",
            trigger: "blur",
          },
        ],
        passWord: [
          {
            required: true,
            message: "Please Enter Pass Word",
            trigger: "blur",
          },
        ],
        PhoneNumber: [
          {
            min: 10,
            max: 10,
            required: true,
            message: "Please Enter Email Address",
            trigger: "blur",
          },
        ],
        resetPhone: [
          {
            required: true,
            message: "Please Enter Email Address",
            trigger: "blur",
          },
          { validator: emailFun, trigger: 'blur' }
        ],
        registerPassWord: [
          {
            required: true,
            message: "Please Enter Pass Word",
            trigger: "blur",
          },
        ],
        resetPassword: [
          {
            required: true,
            message: "Please Enter Pass Word",
            trigger: "blur",
          },
        ],
        code: {
          required: true,
          message: "Please Enter Pass Code",
          trigger: "blur",
        },
        loginCode: {
          required: true,
          message: "Please Enter Pass Code",
          trigger: "blur",
        },
      },
      userInfo: {},
      sendResetTime: 0,
      sendResetTimer: "",
      loading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (this.userInfo) {
        // this.sendResetCode();
      }
      console.log("userInfo", this.userInfo);
    },
   
    // 更改密码
    async changePassword() {
      const data = {
        password: this.sign.resetPassword,
        email: this.userInfo ? this.userInfo.email : this.sign.resetPhone,
        smscode: this.sign.code,
        username: this.userInfo ? this.userInfo.username : "",
      };
      data.username = data.username ? data.username : data.email;
      this.$refs.sign.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          console.log(data)
          const change = await passwordChange(data);
          this.loading = false;
          if (change.code == 200) {
            this.$msg(change.message);
            removeUserInfo();
            removeToken();

            const backLength = window.history.length;
            this.$router.go(-backLength);
            this.$router.replace("/login");
          }
        } else {
          return false;
        }
      });
    },
  },
  components: {
    Consultation,
    Nav,
    Bottom,
  },
};
</script>

<style lang="scss" scoped>
@import "@/baseScss/baseScss.scss";
.body {
  .login {
    @include column;
    justify-content: center;
    min-height: 50vh;

    color: rgb(146, 148, 149);
    .title {
      width: 400px;
      margin: 0 auto;
      font-size: 32px;
      font-weight: 650;
      color: #000;
      margin-bottom: 20px;
    }
    .desc {
      width: 400px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .operate {
      color: #fff;
      .btn {
        @include center;
        cursor: pointer;

        border-radius: 3px;
        width: 400px;
        height: 40px;
        font-weight: 400;
        font-size: 13px;
        // margin-bottom: 25px;
        margin: 0 auto 25px;
      }
      .loading {
        opacity: 0.5;
        span {
          margin-right: 20px;
        }
      }
      .send {
        opacity: 0.2;
      }
      .phone {
        color: #929495;
        .again {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 20px;
          cursor: pointer;
        }
      }
      .register {
        display: flex;
        // width: 140px;
        width: 400px;
        margin: 0 auto;
        justify-content: flex-start;
        .btn {
          width: 140px;
          margin: 0;
          margin-bottom: 20px;
        }
      }
      .login-btn {
        background-color: #000;
      }
      .reset-btn {
        background-color: #000;
      }
      .create-btn {
        background-color: #aaa;
      }
    }
    .el-col {
      .el-input {
        width: 200px;
      }
    }
    .el-input {
      width: 400px;
    }
    .el-form-item {
      @include column;
      // width: 400px;
      align-items: center;
      .el-form-item__content {
        position: relative;
        .int-code {
          width: 400px;
          .el-input {
            width: 250px;
          }
        }
        .get-code {
          position: absolute;
          right: 10px;
          top: 0;
        }
      }
    }
    ::v-deep .el-form--label-top .el-form-item__label {
      width: 400px;
    }
  }
}
::v-deep .popper__arrow {
  display: none !important;
}
::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff !important;
  color: $color;
}
::v-deep .el-form-item__label {
  color: rgb(146, 148, 149);
}
::v-deep .el-input__inner:focus {
  border-color: #333;
}
::v-deep .el-form-item__label:before {
  display: none;
  color: rgb(146, 148, 149) !important;
}
.el-icon-loading {
  color: #fff;
}
</style>
