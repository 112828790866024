import { render, staticRenderFns } from "./askIndex.vue?vue&type=template&id=554f4ee6&scoped=true"
import script from "./askIndex.vue?vue&type=script&lang=js"
export * from "./askIndex.vue?vue&type=script&lang=js"
import style0 from "./askIndex.vue?vue&type=style&index=0&id=554f4ee6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "554f4ee6",
  null
  
)

export default component.exports