/*
 * @Author: LegerVeilchen LegerVeilchen@foxmail.com
 * @Date: 2022-05-30 15:22:44
 * @LastEditors: LegerVeilchen LegerVeilchen@foxmail.com
 * @LastEditTime: 2022-06-07 16:06:13
 * @FilePath: \loan-web\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/permission"; // permission control
import {
  jump,
  msg,
  onLoad,
  close,
  convertTime,
  getDates,
  getTime,
  msgBox,
  getQuarter,
  getQuartorStartDate,
  formatDate,
  befClose,
} from "@/utils/method";
import loadMore from "@/utils/loadMore"; // 下拉加载更多
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
let lang = localStorage.getItem('lang')
const i18n = new VueI18n({
  locale: lang?lang:'en', // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'zh': require('./i18n/zh.json'),  // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
    'en': require('./i18n/en.json')
  }
});
Vue.prototype.$jump = jump; // 跳转
Vue.prototype.$msg = msg; // 接口调用提示
Vue.prototype.$onLoad = onLoad; // 加载提示
Vue.prototype.$close = close; // 关闭加载提示
Vue.prototype.$convertTime = convertTime; // 转换日期(yyyy-MM-dd)
Vue.prototype.$getDates = getDates; // 转换日期(MM月dd日)
Vue.prototype.$getTime = getTime; // 转换日期(HH:mm:ss)
Vue.prototype.$msgBox = msgBox; // 确认弹窗
Vue.prototype.$getQuarter = getQuarter; // 获取季度
Vue.prototype.$getQuartorStartDate = getQuartorStartDate; // 获取季度开始和结束
Vue.prototype.$formatDate = formatDate;
Vue.prototype.$befClose = befClose;

let pageIndex = 0
Vue.prototype.$pageIndex = pageIndex;
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
window.addEventListener("popstate", function () {
  history.pushState(null, null, document.URL)
})
import locale from "element-ui/lib/locale/lang/en";
import "./element-variables.scss";
Vue.use(ElementUI, {
  locale
});
Vue.use(loadMore);
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");