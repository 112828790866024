<template>
	<div style="height: 100vh;display: flex;flex-direction: column;">
		<Nav ></Nav>
		<div ref="echartsContainer" style="width: 100%;flex: 1;"></div>
	</div>
	
</template>

<script>
	import * as echarts from 'echarts';
	import Nav from "@/components/Nav/Nav.vue";
	import {
		getEstimateDetail
	} from "@/api/loans/loans"
	export default {
		components: {
			Nav,
		},
		data() {
			return {
				myChart: null,
				standNum: 25,
				main_data:{},
				xData: 0,
				yData: 0,
				options: {
					title: {
						text: ''
					},
					tooltip: {},
					animationDurationUpdate: 1500,
					animationEasingUpdate: 'quinticInOut',
					series: [{
						type: 'graph',
						layout: 'force',
						force: {
							repulsion: 5000,
							edgeLength: [3, 6],
						},
						symbolSize: 60,
						roam: true,
						label: {
							show: true,

						},
						edgeSymbol: ['circle', 'arrow'],
						edgeSymbolSize: [4, 16],
						edgeLabel: {
							fontSize: 14
						},
						data: [],
						links: [],
						lineStyle: {
							opacity: 1,
							width: 2,
							curveness: 0
						}
					}]
				}
			}

		},
		mounted() {
			
		},
		watch: {

		},
		created() {
			let params = this.$route.params
			let query = this.$route.query
			if (query.record_id != '' && query.record_id != undefined) {
				this.getRecordData(query.record_id)
			} 
		},
		methods: {
			getRecordData(id) {
				const loading = this.$loading({
					lock: true,
					text: '数据加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				getEstimateDetail({
					record_id: id
				}).then(res => {
					this.main_data = JSON.parse(res.data.sub_datas)
					this.init()
					this.initData()
					loading.close()
				}).catch(err => {
					loading.close()
				})
			},
			init() {
				this.myChart = echarts.init(this.$refs.echartsContainer);
			},
			initData() {
				let tempList = []
				this.options.series[0].data = []
				this.options.series[0].links = []
				this.main_data.entity_infos.map(entity => {
					this.addItem(entity)
				})
				//关系图连线
				this.main_data.entity_infos.map((res, index) => {
					if (res.entity_type_id == 6) { //个人联名
						// 股东实体
						res.basic_info.share_info.map((resInfo, ii) => {
							if (resInfo.entity_type_id == 2) {
								this.addRelationData(res.entity_name, resInfo.entity_name, '联名实体')
							}
						})
					}
					if (res.entity_type_id == 1 || res.entity_type_id == 5) { //企业  合伙企业
						// 股东实体
						res.basic_info.share_info.map((resInfo, ii) => {
							this.addRelationData(resInfo.entity_name, res.entity_name, resInfo
								.share_ratio + "%")
						})
					}
					if (res.entity_type_id == 2) { //个人
						//配偶添加至子实体
						let temp = {
							entity_type_id: 2,
							entity_name: res.basic_info.spouse_name
						}
						this.addRelationData(res.basic_info.spouse_name, res.entity_name, '配偶')
					}
					if (res.entity_type_id == 3 || res.entity_type_id == 4) { //家庭信托 ||单元信托
						//受益人信息添加至二级实体
						res.basic_info.benef_info.map(resInfo => {
							this.addRelationData(resInfo.entity_name, res.entity_name, '受益人')
						})
						//受益人持有的其他实体 添加至主实体
						let templist2 = []
						res.basic_info.share_other_info.map(resInfo => {
							if (resInfo.entity_type_id != '' && resInfo.entity_name != '') {
								let find = false
								templist2.map(tempInfo => {
									if (tempInfo.entity_name == resInfo.entity_name) {
										find = true
										tempInfo.entity_names_list.push(resInfo.entity_name)
										tempInfo.share_ratio = parseFloat(tempInfo.share_ratio ?
											tempInfo.share_ratio : 0) + parseFloat(resInfo
											.share_ratio ? resInfo.share_ratio : 0)
									}
								})
								if (!find) {
									resInfo.entity_names_list = []
									resInfo.entity_names_list.push(resInfo.entity_names)
									templist2.push(JSON.parse(JSON.stringify(resInfo)))
								}
							}
						})
						templist2.map(resInfo => {
							if (parseFloat(resInfo.share_ratio) >= this.standNum) {
								this.addTreeParent(resInfo)
								resInfo.entity_names_list.map(listItem => {
									let temp = {
										is_strong: '1',
										entity_type_id: '',
										entity_name: listItem
									}
									res.basic_info.benef_info.map(benef => {
										if (benef.entity_name == listItem) {
											temp.entity_type_id = benef.entity_type_id
										}
									})
									this.addRelationData(listItem, resInfo.entity_name, resInfo
										.share_ratio)
								})
							}
						})
						if (res.basic_info.trustee_info.entity_type_id == 2) {
							this.addRelationData(res.basic_info.trustee_info.entity_name, res.entity_name, '受托人')
						} else {
							this.addRelationData(res.basic_info.trustee_info.entity_name, res.entity_name, '受托人')
						}
					}

				})
				console.log(this.options.series[0].links)
				this.myChart.setOption(this.options);
			},
			addItem(entity) {
				let color = '#697DFF'
				if (entity.entity_type_id == 1) { //企业
					color = '#FE9B4D'
				}
				if (entity.entity_type_id == 2) { //个人
					color = '#5AC581'
				}
				if (entity.entity_type_id == 3) { //家庭信托
					color = '#F9C500'
				}
				if (entity.entity_type_id == 4) { //单元信托
					color = '#4CBDF3'
				}
				if (entity.entity_type_id == 5) { //合伙企业
					color = '#B039E3'
				}
				if (entity.entity_type_id == 6) { //联名实体
					color = '#D54763'
				}
				let temp = {
					name: entity.entity_name,
					category: this.getXYData(),
					itemStyle: {
						color: color,
						borderColor: color, // 节点边框颜色
						borderWidth: 2 // 节点边框宽度

					}
				};
				this.options.series[0].data.push(temp)
			},
			
			addRelationData(start, end, text) {
				if (start && end) {
					this.options.series[0].links.push({
						source: start,
						target: end,
						symbolSize: [5, 8],
						label: {
							show: true,
							formatter: ((res) => {
								return text + ''
							})
						},
						lineStyle: {
							curveness: 0
						}
					})
				}

			},
			getXYData() {
				let length = this.options.series[0].data.length
				let category = 0
				if (length == 0) {
					category = 0
				} else {
					category = 2
				}

				return category
			}
		}

	}
</script>

<style>
</style>