<template>
  <div>
    <el-dialog :title="QWER('留言板')" :visible.sync="dialogVisible" :show-close="true" :close-on-click-modal='false'
               width="1000px" style="border-radius: 20px;margin-top: -8vh">
      <div style="text-align: center;width: 100%;">
        <div class="center-box">
          <el-image style="height: 46px" src="logo.ico"></el-image>
          <div class="center-title">{{QWER('欢迎使用在线留言系统')}}</div>
          <div>
            <el-input :placeholder="QWER('请输入邮箱地址')" v-model="sign.email" style="width: 320px;"></el-input>
          </div>
          <div>
            <el-input :placeholder="QWER('请输入验证码')" v-model="sign.code" type="number" maxlength="6"
                      oninput="if(value.length>6)value=value.slice(0,6)" style="width: 320px;margin-top: 16px">
              <template slot="append" >
                <div @click="sendCode" style="cursor: pointer" v-if="!sendTime">
                  {{ QWER('发送验证码') }}
                </div>
                <div  v-if="sendTime">
                  {{ sendTime }}s
                </div>
              </template>
            </el-input>
          </div>
          <el-button type="primary" style="width: 320px;flex: 1;margin-top: 16px" @click="login">{{ QWER('登录') }}</el-button>
        </div>
      </div>

    </el-dialog>
  </div>

</template>

<script>
import {
  userLogin,
  smsCode,
} from "@/api/login/login.js";
import {
  setToken,
  setUserInfo
} from "@/utils/myAuth";

export default {
  data() {
    return {
      dialogVisible: false,
// 用户名密码
      sign: {
        email: "",
        code: ""
      },
      userInfo: {},
      sendTime: 0,
      sendTimer: "", // 验证码计时器
      sendResetTime: 0,
      sendResetTimer: "",
      codeImg: "",
      loading: false,
      refresh: true,
      load: false,
      checkKey: ''
    };
  },
  created() {
  },
  methods: {
    QWER(message) {
      let temp = this.$t(message)
      return temp
    },
    open() {
      this.sign.email = ''
      this.sign.code = ''
      this.dialogVisible = true
    },
    // 登录
    async login() {
      let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
      if (!reg.test(this.sign.email)) {
        this.$msg(this.QWER('请输入正确的邮箱地址'), "warning");
        return;
      }
      if(!this.sign.code){
        this.$msg(this.QWER('请输入验证码'), "warning");
        return;
      }
      const data = {
        email: this.sign.email,
        code: this.sign.code
      };
      let loading = this.$loading()
      const res = await userLogin(data);
      loading.close()
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: this.QWER('登录成功')
        });
        setToken(res.data.access_token);
        setUserInfo(JSON.stringify(res.data.user_info));
        this.$emit('success')
        this.dialogVisible =false
        if(this.sendTimer){
          clearInterval(this.sendTimer)
        }
      } else {
        this.$message({
          type: 'error',
          message: res.msg
        });
        return false;
      }
    },
    // 发送验证码
    async sendCode(name) {

      if (this.sendTime) {
        return
      }

      let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
      if (!reg.test(this.sign.email)) {
        this.$msg(this.QWER('请输入正确的邮箱地址'), "warning");
        return;
      }
      let loading = this.$loading()
      const code = await smsCode({
        email: this.sign.email
      });
      loading.close()
      if (code.code == 200) {
        this.$message({
          type: "success",
          message: code.data
        })
        this.sendTime = 60;
        this.sendTimer = setInterval(() => {
          this.sendTime--;
          if (this.sendTime == 0) {
            this.sendTime = 0;
            clearInterval(this.sendTimer);
          }
        }, 1000);
      } else {
        this.$message({
          type: "error",
          message: code.msg
        })
      }
    },

  },
};
</script>

<style lang="scss" scoped>

::v-deep .el-form-item {
  margin-bottom: 8px;
}

::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0px;
  line-height: 30px;
}

::v-deep .el-dialog {
  background-color: #F5F5F5;
}

.center-box {
  width: 480px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 0px #EEEEEE;
  border-radius: 15px;
  margin: 20px auto;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 50px;
}

.center-title {
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  line-height: 22px;
  margin-top: 23px;
  margin-bottom: 23px;
}
</style>