/*
 * @Author: LegerVeilchen LegerVeilchen@foxmail.com
 * @Date: 2022-05-30 19:42:33
 * @LastEditors: LegerVeilchen LegerVeilchen@foxmail.com
 * @LastEditTime: 2022-06-24 17:30:31
 * @FilePath: \loan-web\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import {

  Message
} from "element-ui";
// import store from "@/store";
import {
  getToken,
  removeToken,
  removeUserInfo
} from "@/utils/myAuth";
import {
  actionUrl
} from "@/utils/actionUrl";
import router from "@/router/index";

let baseURL = actionUrl;
const service = axios.create({
  baseURL,
  timeout: 120000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {}
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
	let lang = localStorage.getItem('lang')
	config.data.lang = lang?lang:'en'
	
    if (config.method.toLocaleLowerCase() == "get") {
      config.params = config.data;
    } else if (config.method.toLocaleUpperCase() == "post") {
      config.data = config.data;
    }
	
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    // console.log("response", response);
    const res = response.data;
    if (res.code !== 200) {
      if (res.code == 401) {
        Message({
          message: "Please Login",
          type: "error",
          duration: 5 * 1000,
        });
        removeToken();
        removeUserInfo();
        //获取当前路径

        router.push({
          path: "/login?path=/index",
        });
        return res;
      } else {
        console.log(res);

        return res;
      }
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err", error); // for debug
    if (error.response.data.code == 401) {
      Message({
        message: "Please Login",
        type: "error",
        duration: 5 * 1000,
      });
      removeToken();
      removeUserInfo();
      router.push({
          path: "/login?path=/index",
      });
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default service;