const TokenKey = "X-Access-Token",
  userInfo = "userInfo";

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return localStorage.removeItem(TokenKey);
}
export function getUserInfo() {
  return localStorage.getItem(userInfo);
}

export function setUserInfo(info) {
  
  return localStorage.setItem(userInfo, info);
}

export function removeUserInfo() {
  return localStorage.removeItem(userInfo);
}
export function exit() {
  return localStorage.clear();
}
