<template>
  <div class="main">
    <div class="heart">
      <div class="info">
        <p>Get in touch for a consultation.</p>
        <div class="info-btn" @click="jump">CONTACT US NOW →</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    jump() {
      window.open("https://reginsun.com/contact-us");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/baseScss/baseScss.scss";
.main {
  min-width: 1080px;
  width: 100%;
  height: 108px;
  background-color: #de7c2b;
  .info {
    @include between;
    width: 100%;
    height: 100%;
    p {
      font-size: 32px;
      font-weight: 300;
      font-family: "europa";
      color: #fff;
    }
    .info-btn {
      @include center;
      @include mask;
      cursor: pointer;
      border-radius: 3px;
      background-color: #1f2526;
      width: 217px;
      height: 44px;
      color: #fff;
      font-size: 12px;
      letter-spacing: 3px;
    }
  }
}
</style>
