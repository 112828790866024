<template>
	<div class="body">
		<Nav></Nav>
		<div class="main_bg">
			<div style="width: 600px;margin: 50px auto 0px;">
				<div class="tips_main">
					Note: If you are looking to calculate multiple loans, you can add more on Page 2.
					<br/>
					You can alter your information at any time, except the borrower name.
				</div>
				<el-form label-position="top" label-width="80px" style="margin-top: 30px;" :model="mainData"
					ref="ruleForm" :rules='ruleForm'>
						<el-form-item label="What is the purpose of your loan? " >
							<el-radio-group v-model="mainData.firstvalue">
							    <el-radio :label="item.value" v-for="(item,index) in firstlist" style="width: 100%;margin-top: 10px;">{{item.name}}</el-radio>
							  </el-radio-group>
						</el-form-item>
						<div class="flex-row">
							<el-form-item label="Type of Borrower"  prop="entity_type_id" v-if="mainData.firstvalue">
								<el-select v-model="mainData.entity_type_id" clearable placeholder="">
									<el-option v-for="item in typeList" :key="item.id" :label="item.entity_type_name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item :label="QWER('是否转贷')" style="margin-left: 20px;" v-if="hasChoose">
								<el-radio v-model="mainData.is_relend" :label="1">{{QWER('是')}}</el-radio>
								<el-radio v-model="mainData.is_relend" :label="0">{{QWER('否')}}</el-radio>
							</el-form-item>
						</div>
						
						<div v-if="hasChoose">
							
							<div v-for="(item,index) in mainData.debt_info" v-if="mainData.is_relend==1">
								<div class="flex-row" style="align-items: center;">
									<el-form-item label="Loan Type">
										<el-select v-model="item.purpose_id" clearable placeholder="Select">
											<el-option v-for="itemC in loanTypeFuzhai" :key="itemC.id" :label="itemC.loan_purpose"
												:value="itemC.id">
											</el-option>
										</el-select>
									</el-form-item>
									<el-form-item :label="QWER('请输入已有贷款金额')" style="margin-left: 20px;">
										<el-input v-model="item.total" placeholder="Enter" type="number"
											@input="item.total=handleInputNumber($event)"></el-input>
									</el-form-item>
											
									<div style="margin-left: 20px;padding-top: 25px;">
										<el-button class="lease-btn" type="primary" v-if="mainData.debt_info.length==index+1"
											@click="addFuzhaiitem" icon="el-icon-plus" circle></el-button>
										<el-button v-if="mainData.debt_info.length>1" type="primary" class="lease-btn"
											@click="deleteFuzhaiItem(index)" icon="el-icon-delete" circle></el-button>
									</div>
								</div>
							</div>
							<div class="flex-row" v-if="mainData.is_relend==0">
								<el-form-item label="Loan Purpose" prop="loan_type" >
									<el-select v-model="mainData.loan_type" clearable placeholder="Select">
										<el-option v-for="item in loanTypeList" :key="item.id" :label="item.loan_purpose"
											:value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
								<div>
									<el-form-item label="Expected Loan Amount (Optional)" style="margin-left: 20px;">
										<el-input v-model="mainData.target_total" type="number" placeholder="Enter"></el-input>
									</el-form-item>
									
								</div>
							</div>
							<div class="flex-row">
								<el-form-item label="What is the name of the borrower"  prop="entity_name" >
									<el-input v-model="mainData.entity_name" placeholder="Enter" style="width: 240px;"></el-input><br/>
									
								</el-form-item>
							</div>
						</div>
					
				</el-form>
				<div style="margin-top: 20px;">
					<el-button type='primary' @click="save">NEXT</el-button>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Nav from "@/components/Nav/Nav.vue";
	import {
		getEntityTypes,
		getLoanPurposes
	} from "@/api/loans/loans"
	export default {
		components: {
			Nav
		},
		data() {
			return {
				hasChoose:false,
				typeList: [],
				loanTypeList: [],
				tempFuzhaiData: {
					purpose_id: "", //债务类型
					total: "", //债务金额
					is_relend: 1 ,//是否转贷
					pawn_type:'',
					evaluate_value:'',
					building_cost:'',
					rent_total:'',
					presale_total:''
				},
				firstlist:[
					{name:'Home Loan (Owner Occupied) ',value:100003},
					{name:'Home Loan (Investment) ',value:1000031},
					{name:'Business and/or Commercial Loan',value:999},
				],
				loanTypeFuzhai:[],
				ruleForm: {
					entity_type_id: [{
						required: true,
						message: this.QWER('请选择贷款主体类型'),
						trigger: 'change'
					}],
					entity_name: [{
						required: true,
						message:  this.QWER( '请输入贷款主体名称'),
						trigger: 'blur'
					}],
					loan_type: [{
						required: true,
						message:  this.QWER('请选择贷款类型'),
						trigger: 'change'
					}],
					target_total: [{
						required: true,
						message:  this.QWER('请输入贷款金额'),
						trigger: 'blur'
					}]
				},
				mainData: {
					firstvalue:'',
					is_relend:0,//是否转贷
					debt_info:[],
					is_single_main:1,//是否单个实体作为主贷
					entity_type_id: '', //请选择贷款主体类型
					entity_name: '', //请输入贷款主体名称
					loan_type: '', //请选择贷款类型
					target_total: '', //请输入贷款金额
					is_reorganization:'',//已有贷款处理方式
				}
			};
		},
		created() {
			this.getTypeList()
			this.addFuzhaiitem()
			localStorage.setItem("mainData", '')
			localStorage.setItem("entityTreeList", '')
		},
		watch:{
			'mainData.firstvalue':{
				handler(newVal, oldVal){
					this.typeList = []
					this.mainData.entity_type_id=''
					this.getTypeList()
					this.loanTypeList = []
					if(this.firstvalue!=999){
						this.mainData.loan_type = this.firstvalue
					}
					console.log(this.firstvalue)
				}
			},
			'mainData.entity_type_id':{
				handler(newVal, oldVal) {
					this.hasChoose = false
					if(this.mainData.entity_type_id!=''){
						
						this.$confirm('Is this a refinance?', this.QWER('提示'), {
						          confirmButtonText: 'YES',
						          cancelButtonText:'NO',
								  closeOnClickModal:false,
								  closeOnPressEscape:false,
						          type: 'warning'
						        }).then(() => {
									this.hasChoose  =true
									this.mainData.is_relend = 1
								}).catch(() => {
									this.hasChoose  =true
									this.mainData.is_relend = 0
						        });
					}
					
				},
				
			}
		},
		methods: {
			QWER(message) {
				let temp =  this.$t(message)
			   return temp
			},
			addFuzhaiitem() { //新增
				let temp = JSON.parse(JSON.stringify(this.tempFuzhaiData))
				this.mainData.debt_info.push(temp)
			},
			deleteFuzhaiItem(index) {
				this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
				          this.mainData.debt_info.splice(index, 1)
				        }).catch(() => {
				                   
				        });
			},
			getTypeList() {
				getEntityTypes({id:this.mainData.firstvalue}).then(res => {
					this.typeList = res.data
				})
				getLoanPurposes({level:'',id:this.mainData.firstvalue}).then(res => {
					this.loanTypeList = res.data
				})
				getLoanPurposes({
					level: 1,id:this.mainData.firstvalue
				}).then(res => {
					this.loanTypeFuzhai = res.data
				})
			},
			handleInputNumber(value) {
				if (parseFloat(value) < 0) {
					return ''
				}
				return value.replace("e", '')
			},
			save() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.$confirm("This name cannot be changed after this page unless in a new calculation", "Caution", {
						          confirmButtonText: this.QWER('Confirm'),
						          cancelButtonText: this.QWER('Cancel'),
						          type: 'warning'
						        }).then(() => {
						          this.$router.push({
						          	name: "Index",
						          	params: this.mainData
						          });
						        }).catch(() => {
						                   
						        });
						
					} else {
						return false;
					}
				});
			}
		}

	};
</script>

<style lang="scss" scoped>
	@import "@/baseScss/baseScss.scss";

	.body {
		height: 100vh;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background-color: #F5F5F5;
	}

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.flex-col {
		display: flex;
		flex-direction: column;
	}

	.flex-1 {
		flex: 1;
	}

	.main_bg {
		background: #FFFFFF;
		height: 800px;
		box-shadow: 0px 0px 12px 0px rgba(243, 243, 243, 0.5);
		border-radius: 10px;
		margin: 30px;
		overflow: auto;
		
		
	}

	.title_main {
		font-weight: bold;
		font-size: 36px;
		color: #000000;
		line-height: 20px;
	}

	.tips_main {
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		line-height: 20px;
	}
	.text-out{
		font-size: 14px;
		color: #DE7C2B;
		margin-top: -10px;
		cursor: pointer;
	}
</style>