<template>
	<div style="padding: 10px 0px 10px 20px;height: calc(100% - 125px);">
		<div style="overflow-y: auto;width: 100%;height: 100%;" id='content'>
			<span style="font-size: 16px;color: #DE7C2B;line-height: 22px;">
				*{{QWER('如果您没有相关信息可不用填写')}}
			</span>
			<el-form label-position="top" label-width="80px" :model="main_data" ref="ruleForm"
				:rules='ruleForm'>
				
				<div class="flex-col" style="align-items: flex-start;" v-if='main_data.entity_type_id==6'>
					<div class="title_second">{{QWER('个人联名名称')}}
					<span class="text-out" v-if="eeeIndex!=0">
						<i class="el-icon-question"></i>
						*{{QWER('修改实体名称请在引入该实体的地方修改')}}
					</span>
					<span class="text-out" v-if="eeeIndex==0">
						<i class="el-icon-question"></i>
						Name of the first created entity cannot be changed
					</span>
					</div>
					<el-form-item label="" prop="entity_name" :rules="ruleForm.must_input">
						<el-input v-model="main_data.entity_name" disabled placeholder="Enter" style="width: 400px;"></el-input>
					</el-form-item>
				</div>
				<!-- 企业信息 id=1-->
				<div class="flex-col" style="align-items: flex-start;" v-if='main_data.entity_type_id==1||main_data.entity_type_id==5'>
					<div class="title_second">{{QWER('企业名称')}}
					<span class="text-out" v-if="eeeIndex!=0">
						<i class="el-icon-question"></i>
						*{{QWER('修改实体名称请在引入该实体的地方修改')}}
					</span>
					<span class="text-out" v-if="eeeIndex==0">
						<i class="el-icon-question"></i>
						Name of the first created entity cannot be changed
					</span>
					</div>
					<div style="display: flex;">
						<el-form-item label="" prop="entity_name" >
							<el-input v-model="main_data.entity_name" disabled style="width: 400px;"></el-input>
						</el-form-item>
						<div v-if="main_data.xintuolist.length>0" style="font-size: 16px;color: #DE7C2B;line-height: 22px;margin-left: 30px;line-height: 40px;">
							ATF {{main_data.xintuolist.join(',')}}
						</div>
					</div>
				</div>
				<!-- 个人 id=2 -->
				<div class="flex-col" style="align-items: flex-start;" v-if='main_data.entity_type_id==2'>
					<div class="title_second">{{QWER('姓名')}}
					<span class="text-out" v-if="eeeIndex!=0">
						<i class="el-icon-question"></i>
						*{{QWER('修改实体名称请在引入该实体的地方修改')}}
					</span>
					<span class="text-out" v-if="eeeIndex==0">
						<i class="el-icon-question"></i>
						Name of the first created entity cannot be changed
					</span>
					</div>
					<div style="display: flex;">
						<el-form-item label="" prop="entity_name" >
							<el-input v-model="main_data.entity_name" disabled style="width: 400px;"> </el-input>
						</el-form-item>
						<div v-if="main_data.xintuolist.length>0" style="font-size: 16px;color: #DE7C2B;line-height: 22px;margin-left: 30px;line-height: 40px;">
							ATF {{main_data.xintuolist.join(',')}}
						</div>
					</div>
					<div class="title_second">{{QWER('配偶或同居人姓名')}}
					<span class="text-out" >
						<i class="el-icon-question"></i>
						*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
					</span>
					</div>
					<el-form-item label="">
						<el-autocomplete
								clearable
						      v-model="main_data.spouse_name"
						      :fetch-suggestions="querySearch"
							  :disabled='main_data.is_strong==1'
							  style="width: 400px;" 
						      placeholder="Enter"
						    ></el-autocomplete>
					</el-form-item>
					<span class="text-out" style="margin-top: 10px;" v-if="main_data.is_strong==1" @click="toEntity1(main_data.spouse_name)">
						*{{QWER('该数据由xxxxxx创建，点我跳转到该实体修改',main_data.spouse_name)}}
					</span>
				</div>
				<!-- 信托 id=3 ||id=4 -->
				<div class="flex-col" style="align-items: flex-start;"
					v-if='main_data.entity_type_id==3||main_data.entity_type_id==4'>
					<div class="title_second">{{QWER('信托名称')}}</div>
					<span class="text-out" v-if="eeeIndex!=0">
						<i class="el-icon-question"></i>
						*{{QWER('修改实体名称请在引入该实体的地方修改')}}
					</span>
					<span class="text-out" v-if="eeeIndex==0">
						<i class="el-icon-question"></i>
						Name of the first created entity cannot be changed
					</span>
						<el-form-item label="" prop="entity_name" >
							<el-input v-model="main_data.entity_name" disabled style="width: 400px;"></el-input>
						</el-form-item>
					
				</div>
				<!-- 支出 -->
				<div v-if="main_data.entity_type_id==2">
					<div class="title_second">{{QWER('支出')}}</div>
					<div class="flex-row">
						<el-form-item
							:label="QWER('请输入信用卡额度')">
							<el-input v-model="main_data.expenditure.credit_limit" type="number"
								@input="main_data.expenditure.credit_limit=handleInputNumber($event)"
								placeholder="Enter"></el-input>
						</el-form-item>
						<el-form-item
							:label="QWER('请输入DECS年还款额')" style="margin-left: 20px;">
							<el-input v-model="main_data.expenditure.decs" type="number"
								@input="main_data.expenditure.decs=handleInputNumber($event)"
								placeholder="Enter"></el-input>
						</el-form-item>
						<el-form-item
							:label="QWER('其他定期年还款额')" style="margin-left: 20px;">
							<el-input v-model="main_data.expenditure.other" type="number"
								@input="main_data.expenditure.other=handleInputNumber($event)"
								placeholder="Enter"></el-input>
						</el-form-item>
					</div>
					<div class="flex-row" v-if="main_data.spouse_name==''">
						<div class="flex-row">
							<el-form-item :label="QWER('请输入您家庭里18岁以下成员人数')">
								<el-input v-model="main_data.people_count" placeholder="Enter" type="number"></el-input>
							</el-form-item>
						</div>
						<el-form-item
							:label="QWER('取得住宅贷款后居住方式')" style="margin-left: 20px;" v-if="is_main==1&&hasZhuzhai()">
							<el-radio v-model="main_data.expenditure.of_living" label="1">{{QWER('与父母同住')}}</el-radio>
							<el-radio v-model="main_data.expenditure.of_living" label="2">{{QWER('在外租住')}}</el-radio>
							<el-radio v-model="main_data.expenditure.of_living" label="3">{{QWER('入住新物业')}}</el-radio>
						</el-form-item>
						<el-form-item
							:label="QWER('房租租金支出')" style="margin-left: 20px;" v-if='is_main==1&&hasZhuzhai()&&main_data.expenditure.of_living==2'>
							<el-input v-model="main_data.expenditure.rental_total" type="number"
								@input="main_data.expenditure.rental_total=handleInputNumber($event)"
								placeholder="Enter"></el-input>
						</el-form-item>
					</div>
				</div>
				<!-- 收入 -->
				<div v-if="main_data.entity_type_id!=6">
					<div class="title_second">{{QWER('收入')}}</div>
					<div v-for="(item,index) in main_data.income_info">
						<div class="flex-row" style="align-items: center;">
							<el-form-item :label="QWER('请选择类型')" v-if='main_data.entity_type_id==2'>
								<el-select v-model="item.income_id" clearable placeholder="Select" @change="(e)=>chaneIncome(incomeList1,item,e)">
									<el-option v-for="itemC in incomeList1" :key="itemC.id" :label="itemC.name"
										:value="itemC.id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item :label="QWER('请选择类型')" v-if='main_data.entity_type_id!=2'>
								<el-select v-model="item.income_id" clearable placeholder="Select" @change="(e)=>chaneIncome(incomeList2,item,e)">
									<el-option v-for="itemC in incomeList2" :key="itemC.id" :label="itemC.name"
										:value="itemC.id">
									</el-option>
								</el-select>
							</el-form-item>
						
							<el-form-item :label="QWER('请输入金额')" style="margin-left: 20px;">
								<el-input v-model="item.total" placeholder="Enter" type="number"></el-input>
							</el-form-item>
							
							<div style="margin-left: 20px;">
								<el-button class="lease-btn" type="primary"
									@click="clearitem(item)" icon="el-icon-remove" circle></el-button>
								<el-button class="lease-btn" type="primary" v-if="main_data.income_info.length==index+1"
									@click="addShouRuitem()" icon="el-icon-plus" circle></el-button>
								<el-button v-if="main_data.income_info.length>1" type="primary" class="lease-btn"
									@click="deleteShouRuItem11(index)" icon="el-icon-delete" circle></el-button>
							</div>
						</div>
					</div>
				</div>
				
				<div class="flex-col" style="align-items: flex-start;" v-if='main_data.entity_type_id==6'>
					<div class="title_second" ref='61_entity'>{{QWER('实体信息')}}
					<span class="text-out" >
						<i class="el-icon-question"></i>
						*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
					</span>
					</div>
					<div class="flex-row" v-for="(item,index) in main_data.share_info">
						<el-form-item :label="QWER('类型')" >
							<el-select v-model="item.entity_type_id" disabled placeholder="Select"
								style="width: 180px;">
								<el-option v-for="item in typeList" :key="item.id" :label="item.entity_type_name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="QWER('姓名')"  style="margin-left: 20px;">
							<el-autocomplete
									clearable
							      v-model="item.entity_name" :disabled='item.is_strong2==1' 
							      :fetch-suggestions="querySearch"
								  style="width: 180px;" 
							      placeholder="Enter"
							    ></el-autocomplete>
						</el-form-item>
						<el-form-item :label="QWER('税前收入')" style="margin-left: 20px;">
							<el-input v-model="item.all_total" placeholder="Enter" type="number" disabled></el-input>
						</el-form-item>
						<div style="margin-left: 20px;">
							<el-button class="lease-btn" v-if="main_data.share_info.length==index+1&&item.is_strong2!=1" type="primary"
								@click="addShareInfo()" icon="el-icon-plus" circle></el-button>
							<el-button v-if="main_data.share_info.length>1&&item.is_strong2!=1" type="primary"
								class="lease-btn" @click="delShareInfo(index)" icon="el-icon-delete" circle></el-button>
						</div>
					</div>
				</div>
				<div v-if="checkPeople()">
					<div class="flex-row">
						<el-form-item :label="QWER('请输入您家庭里18岁以下成员人数')">
							<el-input v-model="main_data.people_count" placeholder="Enter" type="number"
								@input="main_data.people_count=handleInputNumber($event)"></el-input>
						</el-form-item>
						<el-form-item
							:label="QWER('取得住宅贷款后居住方式')" style="margin-left: 20px;" v-if="childHasZhuzhai">
							<el-radio v-model="main_data.expenditure.of_living" label="1">{{QWER('与父母同住')}}</el-radio>
							<el-radio v-model="main_data.expenditure.of_living" label="2">{{QWER('在外租住')}}</el-radio>
							<el-radio v-model="main_data.expenditure.of_living" label="3">{{QWER('入住新物业')}}</el-radio>
						</el-form-item>
						<el-form-item
							:label="QWER('房租租金支出')" style="margin-left: 20px;" v-if='childHasZhuzhai&&main_data.expenditure.of_living==2'>
							<el-input v-model="main_data.expenditure.rental_total" type="number"
								@input="main_data.expenditure.rental_total=handleInputNumber($event)"
								placeholder="Enter"></el-input>
						</el-form-item>
					</div>
				</div>
				<!-- 负债 -->
				<div class="flex-row">
					<div class="title_second">{{QWER('现有贷款及资产')}}
					<span class="text-out" v-if='main_data.entity_type_id==2'>
						<i class="el-icon-question"></i>
						*{{QWER('这里只填写个人持有数据，与他人联名持有的请在对应的个人联名实体下填写')}}
					</span>
					</div>
				</div>
				
				<div v-for="(item,index) in main_data.debt_info">
					<div class="flex-row" style="align-items: center;">
						<el-form-item :label="QWER('请选择贷款类型')" style='width: 120px;'>
							<el-select v-model="item.purpose_id" clearable placeholder="Select">
								<el-option v-for="itemC in loanTypeFuzhai" :key="itemC.id" :label="itemC.loan_purpose"
									:value="itemC.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="QWER('请输入金额')" style="margin-left: 20px;width: 120px;">
							<el-input v-model="item.total" placeholder="Enter" type="number"
								@input="item.total=handleInputNumber($event)"></el-input>
						</el-form-item>
						<el-form-item :label="QWER('请选择资产类型')" style='margin-left: 20px;width: 120px;'>
							<el-select v-model="main_data.pawn_info[index].pawn_type" clearable placeholder="Select">
								<el-option v-for="itemC in pawnTypeList" :key="itemC.pawn_type" :label="itemC.type_name"
									:value="itemC.pawn_type">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="QWER('请输入评估价值')" style="margin-left: 20px;width: 120px;">
							<el-input v-model="main_data.pawn_info[index].total" placeholder="Enter" type="number"
								@input="main_data.pawn_info[index].total=handleInputNumber($event)"></el-input>
						</el-form-item>
						<el-form-item :label="QWER('增加贷款额度')" style="margin-left: 20px;width: 140px;">
							<el-input v-model="item.total2" placeholder="Enter" type="number"
								@input="item.total2=handleInputNumber($event)"></el-input>
						</el-form-item>
						<div style="margin-left: 20px;">
							<el-button class="lease-btn" type="primary"
								@click="clearitem2(main_data.pawn_info[index],index)" icon="el-icon-remove" circle></el-button>
							<el-button class="lease-btn" type="primary" v-if="main_data.debt_info.length==index+1"
								@click="addFuzhaiitem" icon="el-icon-plus" circle></el-button>
							<el-button v-if="main_data.debt_info.length>1" type="primary" class="lease-btn"
								@click="deleteFuzhaiItem(index)" icon="el-icon-delete" circle></el-button>
							
						</div>
					</div>
					
					<div class="flex-row" style="align-items: center;">

						<el-form-item style='width: 120px;'>
							
						</el-form-item>
						<el-form-item  style="margin-left: 20px;">
							 <el-checkbox v-model="item.is_relend" :true-label='1' :false-label='0'>{{QWER('将该笔贷款转贷')}}</el-checkbox>
						</el-form-item>
						<el-form-item style='width: 120px;'>
							
						</el-form-item>
						<el-form-item  style="margin-left: 20px;">
							<el-checkbox v-model="main_data.pawn_info[index].is_relend" :true-label='1' :false-label='0'>{{QWER('允许抵押该资产')}}</el-checkbox>
						</el-form-item>
						
					</div>
				</div>
				
				<!-- 新增资产 -->
				<div v-if="is_main == 1">
					<div class="title_second">{{QWER('新增资产')}}</div>
					<div v-for="(item,index) in main_data.new_pawn_info" >
						<div class="flex-row" style="align-items: center;">
							<el-form-item :label="QWER('请选择资产类型')">
								<el-select v-model="item.pawn_type" clearable placeholder="Select">
									<el-option v-for="itemC in pawnTypeList" :key="itemC.pawn_type"
										:label="itemC.type_name" :value="itemC.pawn_type">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item :label="QWER('请输入资产价值')" style="margin-left: 20px;">
								<el-input v-model="item.total" placeholder="Enter" type="number"
									@input="item.total=handleInputNumber($event)"></el-input>
							</el-form-item>
							<el-form-item  style="margin-left: 20px;padding-top: 30px">
								<el-checkbox v-model="item.is_relend" :true-label='1' :false-label='0'>{{QWER('允许抵押该资产')}}</el-checkbox>
							</el-form-item>
							<div style="margin-left: 20px;">
								<el-button class="lease-btn" v-if="main_data.new_pawn_info.length==index+1" type="primary"
									@click="addNewZichanitem" icon="el-icon-plus" circle></el-button>
								<el-button v-if="main_data.new_pawn_info.length>1" type="primary" class="lease-btn"
									@click="deleteNewZichanItem(index)" icon="el-icon-delete" circle></el-button>
							</div>
						</div>
					</div>
					<div class="title_second" v-if="hasNewBuilding()">{{QWER('新建物业')}}</div>
					<div v-for="(item,index) in loan_purpose" v-if="item.is_relend!=1&&(item.purpose_id==100007||item.purpose_id==100008||item.purpose_id==100009)">
						<div class="flex-row" style="align-items: center;">
							<el-form-item :label="QWER('目标金额')" >
								<el-input v-model="item.target_total"  type="number" disabled></el-input>
							</el-form-item>
							<el-form-item :label="QWER('资产类型')" style="margin-left: 20px;">
								<el-select v-model="item.purpose_id" clearable placeholder="Select" disabled>
									<el-option v-for="itemC in loanTypeDaikuang" :key="itemC.id"
										:label="itemC.loan_purpose" :value="itemC.id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item :label="QWER('完工评估价值')" style="margin-left: 20px;">
								<el-input v-model="item.evaluate_value" placeholder="Enter" type="number"
									@input="item.evaluate_value=handleInputNumber($event)"></el-input>
							</el-form-item>
							<el-form-item :label="QWER('总建筑成本')" style="margin-left: 20px;">
								<el-input v-model="item.building_cost" placeholder="Enter" type="number"
									@input="item.building_cost=handleInputNumber($event)"></el-input>
							</el-form-item>
							<el-form-item :label="QWER('完工租金')" style="margin-left: 20px;" v-if="item.purpose_id == 100008">
								<el-input v-model="item.rent_total" placeholder="Enter" type="number"
									@input="item.rent_total=handleInputNumber($event)"></el-input>
							</el-form-item>
							<el-form-item :label="QWER('预售金额')" style="margin-left: 20px;" v-if="item.purpose_id == 100009">
								<el-input v-model="item.presale_total" placeholder="Enter" type="number"
									@input="item.presale_total=handleInputNumber($event)"></el-input>
							</el-form-item>
						</div>
					</div>
					<div v-for="(item,index) in main_data.debt_info" v-if="item.is_relend==1&&(item.purpose_id==100007||item.purpose_id==100008||item.purpose_id==100009)">
						<div class="flex-row" style="align-items: center;">
							<el-form-item :label="QWER('目标金额')" >
								<el-input v-model="item.total"  type="number" disabled></el-input>
							</el-form-item>
							<el-form-item :label="QWER('资产类型')" style="margin-left: 20px;">
								<el-select v-model="item.purpose_id" clearable placeholder="Select" disabled>
									<el-option v-for="itemC in loanTypeDaikuang" :key="itemC.id"
										:label="itemC.loan_purpose" :value="itemC.id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item :label="QWER('完工评估价值')" style="margin-left: 20px;">
								<el-input v-model="item.evaluate_value" placeholder="Enter" type="number"
									@input="item.evaluate_value=handleInputNumber($event)"></el-input>
							</el-form-item>
							<el-form-item :label="QWER('总建筑成本')" style="margin-left: 20px;">
								<el-input v-model="item.building_cost" placeholder="Enter" type="number"
									@input="item.building_cost=handleInputNumber($event)"></el-input>
							</el-form-item>
							<el-form-item :label="QWER('完工租金')" style="margin-left: 20px;" v-if="item.purpose_id == 100008">
								<el-input v-model="item.rent_total" placeholder="Enter" type="number"
									@input="item.rent_total=handleInputNumber($event)"></el-input>
							</el-form-item>
							<el-form-item :label="QWER('预售金额')" style="margin-left: 20px;" v-if="item.purpose_id == 100009">
								<el-input v-model="item.presale_total" placeholder="Enter" type="number"
									@input="item.presale_total=handleInputNumber($event)"></el-input>
							</el-form-item>
						</div>
					</div>
				</div>
				
				<!-- 企业信息 id=1-->
				<div class="flex-col" style="align-items: flex-start;" v-if='main_data.entity_type_id==1'>
					<div>
						<div class="title_second">{{QWER('董事信息')}}
						<span class="text-out" >
							<i class="el-icon-question"></i>
							*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
						</span></div>
						<div style="width: 100%;display: flex;flex-wrap: wrap;">
							<div class="flex-row" v-for="(item,index) in main_data.director_info_list"
								style="width: auto;">
								<el-form-item :rules="ruleForm.must_input">
									<el-autocomplete
									      v-model="item.name"
									      :fetch-suggestions="querySearch"
										  style="width: 180px;" 
									      :placeholder="QWER('请输入董事名称')"
									    ></el-autocomplete>
								</el-form-item>
								<div style="margin-left: 20px;margin-right: 30px;">
									<el-button class="lease-btn" type="primary"
										v-if="main_data.director_info_list.length==index+1" @click="addDirectorInfo"
										icon="el-icon-plus" circle></el-button>
									<el-button v-if="main_data.director_info_list.length>1" type="primary"
										class="lease-btn" @click="delDirectorInfo(index)" icon="el-icon-delete"
										circle></el-button>
								</div>
							</div>
						</div>
						<div class="title_second" ref='51_entity'>{{QWER('股东信息')}}
						<span class="text-out" >
							<i class="el-icon-question"></i>
							*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
						</span>
						</div>
						
						<div style="padding-bottom: 10px;"  v-for="(item,index) in main_data.share_info">
							<div class="flex-row">
								<el-form-item label="" :rules="ruleForm.must_choose">
									<el-select v-model="item.entity_type_id" clearable :disabled='item.is_strong==1||item.is_strong2==1'
										:placeholder="QWER('请选择股东类型')" style="width: 180px;">
										<el-option v-for="item in typeList" :key="item.id" :label="item.entity_type_name"
											:value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="" :rules="ruleForm.must_input" style="margin-left: 20px;">
								
									<el-autocomplete
									      v-model="item.entity_name"
									      :fetch-suggestions="querySearch"
										  :disabled='item.is_strong==1||item.is_strong2==1'
										  style="width: 180px;" 
									      :placeholder="QWER('请输入股东名称')"
									    ></el-autocomplete>
								</el-form-item>
								<el-form-item label="" :rules="ruleForm.must_input" style="margin-left: 20px;width: 140px;">
									<el-input v-model="item.share_ratio" :disabled='item.is_strong==1' @blur="checkRatio(index)"
										@input="item.share_ratio = handleInput($event)" type="number" :max="100" :min="0"
										:placeholder="QWER('请输入持股比例')">
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
								<div style="margin-left: 20px;">
									<el-button class="lease-btn" type="primary" v-if="item.is_strong!=1"
										@click="clearitem(item)" icon="el-icon-remove" circle></el-button>
									<el-button class="lease-btn" v-if="main_data.share_info.length==index+1" type="primary"
										@click="addShareInfo()" icon="el-icon-plus" circle></el-button>
									<el-button v-if="main_data.share_info.length>1&&item.is_strong!=1" type="primary"
										class="lease-btn" @click="delShareInfo(index)" icon="el-icon-delete"
										circle></el-button>
								</div>
							</div>
							<span class="text-out1" v-if="item.is_strong==1" @click="toEntity(item)">
								*{{QWER('该数据由xxxxxx创建，点我跳转到该实体修改',item.target.entity_name)}}
							</span>
						</div>
						<div class="title_second" ref='52_entity'>{{QWER('股东及董事持有的其他实体')}}
						<span class="text-out" >
							<i class="el-icon-question"></i>
							*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
						</span></div>
						<div v-for="(item,index) in main_data.share_other_info">
							<div  class="flex-row">
								<el-form-item label="" :rules="ruleForm.must_choose">
									<el-select v-model="item.entity_names" :disabled='item.is_strong==1'
										:placeholder="QWER('请选择股东及董事')" style="width: 180px;">
										<el-option v-for="item in getShareOtherInfoList()" :key="item" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="" :rules="ruleForm.must_choose" style="margin-left: 20px;">
									<el-select v-model="item.entity_type_id" clearable :disabled='item.is_strong==1'
										@change="(e)=>changeEntityType(item,e)" :placeholder="QWER('请选择其他实体类型')"
										style="width: 180px;">
										<el-option v-for="item in typeList2" :key="item.id" :label="item.entity_type_name"
											:value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="" :rules="ruleForm.must_input" style="margin-left: 20px;">
									
									<el-autocomplete
									      v-model="item.entity_name"
									      :fetch-suggestions="querySearch2"
										  :disabled='item.is_strong==1'
										  style="width: 180px;" 
									      :placeholder="QWER('请输入其他实体名称')"
									    ></el-autocomplete>
								</el-form-item>
								
								
								<el-form-item label=""
									v-if="item.entity_type_id!=3&&item.entity_type_id!=4&&item.entity_type_id!=6"
									:rules="ruleForm.must_input" style="margin-left: 20px;width: 140px;">
									<el-input v-model="item.share_ratio" :disabled='item.is_strong==1'
										@input="item.share_ratio = handleInput($event)" type="number" :max="100" :min="0"
										:placeholder="QWER('请输入持股比例')">
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
								<div style="margin-left: 20px;">
									<el-button class="lease-btn" type="primary" v-if="item.is_strong!=1"
										@click="clearitem(item)" icon="el-icon-remove" circle></el-button>
									<el-button class="lease-btn" v-if="main_data.share_other_info.length==index+1"
										type="primary" @click="addShareOtherInfo()" icon="el-icon-plus" circle></el-button>
									<el-button v-if="main_data.share_other_info.length>1&&item.is_strong!=1" type="primary"
										class="lease-btn" @click="delShareOtherInfo(index)" icon="el-icon-delete"
										circle></el-button>
								</div>
							</div>
							<span class="text-out1" v-if="item.is_strong==1" @click="toEntity(item)">
								*{{QWER('该数据由xxxxxx创建，点我跳转到该实体修改',item.target.entity_name)}}
							</span>
							
						</div>
					</div>
				</div>
				<!-- 合伙企业 id=5 -->
				<div class="flex-col" style="align-items: flex-start;" v-if='main_data.entity_type_id==5'>
					<div>
						<div class="title_second" ref='51_entity'>{{QWER('合伙人信息')}}
						<span class="text-out" >
							<i class="el-icon-question"></i>
							*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
						</span>
						</div>
						<div style="padding-bottom: 10px;" v-for="(item,index) in main_data.share_info">
							<div  class="flex-row">
								<el-form-item label="" :prop="`share_info.${index}.entity_type_id`"
									:rules="ruleForm.must_choose">
									<el-select v-model="item.entity_type_id" clearable :disabled='item.is_strong==1'
										@change="(e)=>changeEntityType(item,e)" :placeholder="QWER('请选择合伙人类型')"
										style="width: 180px;">
										<el-option v-for="item in typeList" :key="item.id" :label="item.entity_type_name"
											:value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="" :prop="`share_info.${index}.entity_name`" style="margin-left: 20px;">
									<el-autocomplete
									      v-model="item.entity_name"
									      :fetch-suggestions="querySearch"
										  :disabled='item.is_strong==1'
										  style="width: 180px;" 
									      :placeholder="QWER('请输入合伙人名称')"
									    ></el-autocomplete>
								</el-form-item>
								<el-form-item label="" :prop="`share_info.${index}.share_ratio`"
									v-if="item.entity_type_id!=3&&item.entity_type_id!=4&&item.entity_type_id!=6"
									style="margin-left: 20px;width: 200px;">
									<el-input v-model="item.share_ratio" :disabled='item.is_strong==1' @blur="checkRatio(index)"
										@input="item.share_ratio = handleInput($event)" type="number" :max="100" :min="0"
										:placeholder="QWER('请输入合伙份额')">
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
								<div style="margin-left: 20px;">
									<el-button class="lease-btn" type="primary" v-if="item.is_strong!=1"
										@click="clearitem(item)" icon="el-icon-remove" circle></el-button>
									<el-button class="lease-btn" v-if="main_data.share_info.length==index+1" type="primary"
										@click="addShareInfo()" icon="el-icon-plus" circle></el-button>
									<el-button v-if="main_data.share_info.length>1&&item.is_strong!=1" type="primary"
										class="lease-btn" @click="delShareInfo(index)" icon="el-icon-delete"
										circle></el-button>
								</div>
							</div>
							<span class="text-out1" v-if="item.is_strong==1" @click="toEntity(item)">
								*{{QWER('该数据由xxxxxx创建，点我跳转到该实体修改',item.target.entity_name)}}
							</span>
						</div>
						<div class="title_second" ref='52_entity'>{{QWER('合伙人持有的其他实体')}}
						<span class="text-out" >
							<i class="el-icon-question"></i>
							*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
						</span>
						</div>
						<div v-for="(item,index) in main_data.share_other_info">
							<div class="flex-row" >
								<el-form-item label="" :rules="ruleForm.must_choose">
									<el-select v-model="item.entity_names" :disabled='item.is_strong==1' clearable
										style="width: 180px;" :placeholder="QWER('请选择股东及董事')">
										<el-option v-for="item in getShareOtherInfoList()" :key="item" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="" :rules="ruleForm.must_choose" style="margin-left: 20px;">
									<el-select v-model="item.entity_type_id" :disabled='item.is_strong==1' clearable
										@change="(e)=>changeEntityType(item,e)" :placeholder="QWER('请选择其他实体类型')"
										style="width: 180px;">
										<el-option v-for="item in typeList2" :key="item.id" :label="item.entity_type_name"
											:value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="" :rules="ruleForm.must_input" style="margin-left: 20px;">
									<el-autocomplete
									      v-model="item.entity_name"
									      :fetch-suggestions="querySearch"
										  :disabled='item.is_strong==1'
										  style="width: 180px;" 
									      :placeholder="QWER('请输入其他实体名称')"
									    ></el-autocomplete>
								</el-form-item>
								<el-form-item label=""
									v-if="item.entity_type_id!=3&&item.entity_type_id!=4&&item.entity_type_id!=6"
									:rules="ruleForm.must_input" style="margin-left: 20px;width: 200px;">
									<el-input v-model="item.share_ratio" :disabled='item.is_strong==1'
										@input="item.share_ratio = handleInput($event)" type="number" :max="100" :min="0"
										:placeholder="QWER('请输入持股比例')">
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
								<div style="margin-left: 20px;">
									<el-button class="lease-btn" type="primary" v-if="item.is_strong!=1"
										@click="clearitem(item)" icon="el-icon-remove" circle></el-button>
									<el-button class="lease-btn" v-if="main_data.share_other_info.length==index+1"
										type="primary" @click="addShareInfo()" icon="el-icon-plus" circle></el-button>
									<el-button v-if="main_data.share_other_info.length>1&&item.is_strong==1" type="primary"
										class="lease-btn" @click="delShareInfo(index)" icon="el-icon-delete"
										circle></el-button>
								</div>
							</div>
							<span class="text-out1" v-if="item.is_strong==1" @click="toEntity(item)">
								*{{QWER('该数据由xxxxxx创建，点我跳转到该实体修改',item.target.entity_name)}}
							</span>
						</div>
					</div>
				</div>
				
				<!-- 信托 id=3 ||id=4 -->
				<div class="flex-col" style="align-items: flex-start;"
					v-if='main_data.entity_type_id==3||main_data.entity_type_id==4'>
					<div >
						<div class="title_second" ref='41_entity' v-if='main_data.entity_type_id==3'>{{QWER('受益人信息')}}
						<span class="text-out" >
							<i class="el-icon-question"></i>
							*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
						</span>
						</div>
						<div class="title_second" ref='41_entity' v-if='main_data.entity_type_id==4'>{{QWER('持有人信息')}}
						<span class="text-out" >
							<i class="el-icon-question"></i>
							*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
						</span>
						</div>
						<div class="flex-row" v-for="(item,index) in main_data.benef_info">
							<el-form-item label="">
								<el-select v-model="item.entity_type_id" :disabled='item.is_strong==1' clearable
									:placeholder="QWER('请选择实体类型')">
									<el-option v-for="item in typeList" :key="item.id" :label="item.entity_type_name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="" style="margin-left: 20px;">
								<el-autocomplete
								      v-model="item.entity_name"
								      :fetch-suggestions="querySearch"
									  :disabled='item.is_strong==1'
									  style="width: 180px;" 
								      :placeholder="QWER('请输入实体名称')"
								    ></el-autocomplete>
							</el-form-item>
							<div style="margin-left: 20px;">
								<el-button class="lease-btn" type="primary" v-if="main_data.benef_info.length==index+1"
									@click="addBenefInfo()" icon="el-icon-plus" circle></el-button>
								<el-button v-if="main_data.benef_info.length>1" type="primary" class="lease-btn"
									@click="delBenefInfo(index)" icon="el-icon-delete" circle></el-button>
							</div>
						</div>
						<div class="title_second" ref='42_entity' v-if='main_data.entity_type_id==3'>{{QWER('受益人持有的其他实体')}}
						<span class="text-out" >
							<i class="el-icon-question"></i>
							*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
						</span>
						</div>
						<div class="title_second" ref='42_entity' v-if='main_data.entity_type_id==4'>{{QWER('持有人持有的其他实体')}}
						<span class="text-out" >
							<i class="el-icon-question"></i>
							*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
						</span>
						</div>
						<div class="flex-row" v-for="(item,index) in main_data.share_other_info">
							<el-form-item label="" :rules="ruleForm.must_choose">
								<el-select v-model="item.entity_names" clearable style="width: 180px;"
									:placeholder="main_data.entity_type_id==3?QWER('请选择受益人'):QWER('请选择持有人')" :disabled='item.is_strong==1' >
									<el-option v-for="item in getBenefInfoList()" :key="item" :label="item"
										:value="item">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="" :rules="ruleForm.must_choose" style="margin-left: 20px;">
								<el-select v-model="item.entity_type_id" clearable
									@change="(e)=>changeEntityType(item,e)" :placeholder="QWER('请选择其他实体类型')"
									style="width: 180px;" :disabled='item.is_strong==1' >
									<el-option v-for="item in typeList2" :key="item.id" :label="item.entity_type_name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="" :rules="ruleForm.must_input" style="margin-left: 20px;">
								<el-autocomplete
								      v-model="item.entity_name"
								      :fetch-suggestions="querySearch"
									  :disabled='item.is_strong==1'
									  style="width: 180px;" 
								      :placeholder="QWER('请输入其他实体名称')"
								    ></el-autocomplete>
							</el-form-item>
							<el-form-item label=""
								v-if="item.entity_type_id!=3&&item.entity_type_id!=4&&item.entity_type_id!=6"
								:rules="ruleForm.must_input" style="margin-left: 20px;width: 200px;" >
								<el-input v-model="item.share_ratio" @input="item.share_ratio = handleInput($event)"
									type="number" :max="100" :min="0" :placeholder="QWER('请输入持股比例')" :disabled='item.is_strong==1'>
									<template slot="append">%</template>
								</el-input>
							</el-form-item>
							<div style="margin-left: 20px;">
								<el-button class="lease-btn" type="primary" v-if="item.is_strong!=1"
									@click="clearitem(item)" icon="el-icon-remove" circle></el-button>
								<el-button class="lease-btn" v-if="main_data.share_other_info.length==index+1"
									vs @click="addShareOtherInfo()" icon="el-icon-plus" circle></el-button>
								<el-button v-if="main_data.share_other_info.length>1&&item.is_strong!=1" type="primary" class="lease-btn"
									@click="delShareOtherInfo(index)" icon="el-icon-delete" circle></el-button>
							</div>
						</div>
					</div>
					<div class="title_second">{{QWER('受托人信息')}}
					<span class="text-out" >
						<i class="el-icon-question"></i>
						*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
					</span>
					</div>
					<div class="flex-row">
						<el-form-item label="">
							<el-select v-model="main_data.trustee_info.entity_type_id" clearable :placeholder="QWER('请选择实体类型')">
								<el-option v-for="item in xintuoList" :key="item.id" :label="item.entity_type_name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="" style="margin-left: 20px;">
							
							<el-autocomplete
							      v-model="main_data.trustee_info.entity_name"
							      :fetch-suggestions="querySearch"
								  style="width: 180px;" 
							      :placeholder="QWER('请输入名称')"
							    ></el-autocomplete>
						</el-form-item>
					</div>
				</div>
				<div v-if="main_data.entity_name!=''&&main_data.entity_type_id!=6">
					<div class="title_second" ref='1_entity'>{{QWER('持有的其他实体')}} {{main_data.entity_name}}
					<span class="text-out" >
						<i class="el-icon-question"></i>
						*{{QWER('修改实体类型和名称会导致该实体已填写数据清空，请谨慎操作')}}
					</span>
					</div>
					<div style="padding-bottom: 10px;" v-for="(item,index) in main_data.entity_other_info">
						<div class="flex-row">
							<el-form-item label="" :rules="ruleForm.must_choose">
								<el-select v-model="item.entity_type_id" clearable :disabled='item.is_strong==1'
									@change="(e)=>changeEntityType(item,e)" :placeholder="QWER('请选择实体类型')" style="width: 180px;">
									<el-option v-for="item in typeList2" :key="item.id" :label="item.entity_type_name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="" :rules="ruleForm.must_input" style="margin-left: 20px;">
								<el-autocomplete
								      v-model="item.entity_name"
								      :fetch-suggestions="querySearch"
									   :disabled='item.is_strong==1'
									  style="width: 180px;" 
								      :placeholder="QWER('请输入实体名称')"
								    ></el-autocomplete>
							</el-form-item>
							<el-form-item label=""
								v-if="item.entity_type_id!=3&&item.entity_type_id!=4&&item.entity_type_id!=6"
								:rules="ruleForm.must_input" style="margin-left: 20px;width: 200px;">
								<el-input v-model="item.share_ratio" :disabled='item.is_strong==1'
									@input="item.share_ratio = handleInput($event)" type="number" :max="100" :min="0"
									:placeholder="QWER('请输入持股比例')">
									<template slot="append">%</template>
								</el-input>
							</el-form-item>
							<div style="margin-left: 20px;">
								<el-button class="lease-btn" type="primary" v-if="item.is_strong!=1"
									@click="clearitem(item)" icon="el-icon-remove" circle></el-button>
								<el-button class="lease-btn" v-if="main_data.entity_other_info.length==index+1" type="primary"
									@click="addEntityOtherInfo()" icon="el-icon-plus" circle></el-button>
								<el-button v-if="main_data.entity_other_info.length>1&&item.is_strong!=1" type="primary" class="lease-btn"
									@click="delEntityOtherInfo(index)" icon="el-icon-delete" circle></el-button>
							</div>
						</div>
						<span class="text-out1" v-if="item.is_strong==1" @click="toEntity(item)">
							*{{QWER('该数据由xxxxxx创建，点我跳转到该实体修改',item.target.entity_name)}}
						</span>
						
					</div>
				</div>
				

			</el-form>
		</div>
		<div class="flex-row" style="margin-top: 20px;">
			<el-button type="primary" @click="save(99)">{{QWER('确认')}}</el-button>
		</div>
	</div>
</template>

<script>
	import {
		getLoanPurposes,
		getPawnType,getIncomeList
	} from "@/api/loans/loans"
	export default {
		name: 'xinxi',
		props: {
			eeeIndex:0,
			list: Array, //实体列表
			typeList: Array, //实体类型1
			typeList2: Array, //实体类型2
			is_main: '',
			loan_purpose:{
				type:Array,
				default:[]
			},
			childHasZhuzhai:{
				type:Boolean,
				default:false
			},
			main_data: {
				type: Object,
				default: {
					entity_name: "",
					hasFuzhai: false,
					entity_type_id: '',
					director_info_list: [{
						name: ''
					}], //针对企业才有
					director_info: "", //针对企业才有
					share_info_true: true,
					share_info: [ //股东/合伙人信息-针对企业和合伙企业才有
						{
							"is_strong": '0',
							"entity_type_id": "", //股东类型（个人、企业、家庭信托、单元信托）-传 id
							"entity_name": "", //股东 1/合伙人 1
							"share_ratio": '' //0.25
						}
					],
					share_other_info: [ //股东/董事/合伙人/受益人持有的其他实体信息-针对企业、合伙企业、信托才有
						{
							"is_strong": '0',
							"entity_names_list": [],
							"entity_names": "", //董事 1/合伙人 1,董事 2/合伙人 2,股东 1,股东 2..... 多个英文逗号隔开
							"entity_type_id": "", //持有其他实体类型-传 id
							"entity_name": "", //持有其他实体名称
							"share_ratio": "" //0.25
						},
					],
					entity_other_info: [ //当前实体持有其他实体信息-针对企业、合伙企业、信托才有
						{
							"is_strong": '0',
							"entity_type_id": "", //实体类型（个人、企业、家庭信托、单元信托）-传 id
							"entity_name": "", //当前实体持有其他实体的实体名称
							"share_ratio": '' //0.25
						}
					],
					spouse_name: "",
					benef_info: [ //受益人/持有人信息-针对信托才有
						{
							"is_strong": '0',
							"entity_name": "", //受益人 2
							"entity_type_id": "" //股东类型（个人、企业、家庭信托、单元信托）-传 id
						}
					],
					trustee_info: { //托管人信息-针对信托才有,托管人只可能一个
						entity_type_id: "", //托管人类型（个人、企业）-传 id
						entity_name: "" //托管人名称
					},
					trustee_director_info_list: [{
						name: ''
					}],
					trustee_director_info: "", //针对信托并且托管人类型为企业才有  托管人董事信息，多个英文逗号隔开
					trustee_share_info: [ //托管人股东信息-针对信托并且托管人类型为企业才有
						{
							"is_strong": '0',
							"entity_type_id": "", //股东类型（个人、企业）-传 id
							"entity_name": "", //股东名称
							"share_ratio": '', //0.25
						}
					],
					trustee_share_other_info: [ //受托人的股东及董事持有其他实体信息
						{
							"is_strong": '0',
							"entity_names": "", //董事 1/股东 1 ,董事 2/股东 2,股东 1,股东 2..... 多个英文逗号隔开
							"entity_type_id": "", //持有其他实体类型-传 id
							"entity_name": "", //持有其他实体名称
							"share_ratio": "" //0.25
						}
					],
					income_info:[{
						"income_id":"",
						"income_name":"",
						"total":"",
					}],
					debt_info: [{
						purpose_id: "", //债务类型
						total: "", //债务金额
						is_relend: 0 ,//是否转贷
						pawn_type:'',
						evaluate_value:'',
						building_cost:'',
						rent_total:'',
						presale_total:''
						
					}],
					pawn_info: [{
						"pawn_type": "",
						"total": "",
						"is_relend":0
					}],
					new_pawn_info: [{
						pawn_type: "",
						income_total: "",
						total: "",
						is_relend:1,
					}]
				}
			}
		},
		watch: {
			'main_data.director_info_list': {
				handler(newVal, oldVal) {
					this.main_data.director_info = ""
					newVal.map(res => {
						if (this.main_data.director_info != '') {
							this.main_data.director_info += ',' + res.name
						} else {
							this.main_data.director_info = res.name
						}
					})

				},
				deep: true
			},
			'main_data.share_info': {
				handler(newVal, oldVal) {
					if (this.main_data.entity_type_id == 6) {
						this.main_data.share_info.map(res => {
							res.entity_type_id = 2
						})
					}
				},
				deep: true
			},
		},
		created() {
			this.getInitData()
			if (this.main_data.entity_type_id == 6) {
				this.main_data.share_info.map(res => {
					res.entity_type_id = 2
				})
			}
		},
		data() {
			return {
				incomeList1:[],//个人收入
				incomeList2:[],//非个人收入
				ruleForm: {
					must_input: [{
						required: true,
						message: this.QWER('请输入'),
						trigger: 'blur'
					}],
					must_choose: [{
						required: true,
						message: this.QWER('请选择'),
						trigger: 'change'
					}],
				},
				pawnTypeList: [],
				xintuoList: [{
						"id": 1,
						"entity_type_name": this.QWER("企业")
					},
					{
						"id": 2,
						"entity_type_name": this.QWER("个人")
					}
				],
				shareInfoTemp: {
					"is_strong": '0',
					"entity_type_id": "", //股东类型（个人、企业、家庭信托、单元信托）-传 id
					"entity_name": "", //股东 1/合伙人 1
					"share_ratio": '' //0.25
				},
				shareOtherInfoTemp: {
					"is_strong": '0',
					"entity_names_list": [],
					"entity_names": "", //董事 1/合伙人 1,董事 2/合伙人 2,股东 1,股东 2..... 多个英文逗号隔开
					"entity_type_id": "", //持有其他实体类型-传 id
					"entity_name": "", //持有其他实体名称
					"share_ratio": "" //0.25
				},
				entityOtherInfoTemp: {
					"is_strong": '0',
					"entity_type_id": "", //实体类型（个人、企业、家庭信托、单元信托）-传 id
					"entity_name": "", //当前实体持有其他实体的实体名称
					"share_ratio": '' //0.25
				},
				benefInfoTemp: {
					"is_strong": '0',
					"entity_name": "", //受益人 2
					"entity_type_id": "" //股东类型（个人、企业、家庭信托、单元信托）-传 id
				},
				incomeTemp:{
					"income_id":"",
					"income_name":"",
					"total":"",
				},

				tempFuzhaiData: {
					purpose_id: "", //债务类型
					total: "", //债务金额
					is_relend: 0 ,//是否转贷
					pawn_type:'',
					evaluate_value:'',
					building_cost:'',
					rent_total:'',
					presale_total:''
				},
				tempZichanData: {
					'pawn_type': "", //债务类型
					'total': "" ,//债务金额
					"is_relend":1
				},
				tempNewZichanData: {
					'pawn_type': "", //债务类型
					'income_total': "", //债务类型
					'total': "" ,//债务金额
					'is_relend':1
				},
				loanTypeFuzhai: [], //负债类型
				loanTypeDaikuang:[]//贷款类型
			}
		},
		methods: {
			QWER(message,obj1) {
				let temp =  this.$t(message)
				if(obj1){
					temp = temp.replace('xxxxxx',obj1)
				}
				// if(temp==message){
				// 	let langs =localStorage.getItem('lang')
				// 	let langlist = []
				// 	if(langs){
				// 		langlist=JSON.parse(langs)
				// 	}
				// 	langlist.push(temp)
				// 	let temppp= langlist.filter((item, index, self) => self.indexOf(item) === index);
				// 	localStorage.setItem('lang',JSON.stringify(temppp))
				// 	console.log(temppp)
				// }
			   return temp
			},
			hasNewBuilding(){
				let has= false
				this.loan_purpose.map(item=>{
					if(item.is_relend!=1&&(item.purpose_id==100007||item.purpose_id==100008||item.purpose_id==100009)){
						has = true
					}
				})
				this.main_data.debt_info.map(item=>{
					if(item.is_relend==1&&(item.purpose_id==100007||item.purpose_id==100008||item.purpose_id==100009)){
						has = true
					}
				})
				return has
			},
			chaneIncome(list,item,e){
				list.map(res=>{
					if(item.income_id == res.id){
						item.income_name =res.name
					}
				})
			},
			hasZhuzhai(){
				let has = false
				this.main_data.debt_info.map(res=>{
					if((res.purpose_id=='100003'||res.purpose_id=='1000031')&&res.total){
						has = true
					}
				})
				
				this.loan_purpose.map(res=>{
					if((res.purpose_id=='100003'||res.purpose_id=='1000031')&&res.target_total){
						has = true
					}
				})
				return has
			},
			checkPeople(){
				let res =false
				if(this.main_data.entity_type_id==6){
					let only = true
					this.main_data.share_info.map((res,index)=>{
						if(index>1&&res.entity_name!=''){
							only = false
						}
					})
					if(only){
						res = true
					}
					if(!res){
						this.main_data.people_count = ''
					}
				}
				
				return res
			},
			scrollTop() {
				document.getElementById('content').scrollTop = 0
			},
			nameBlur(e,value,item){
				console.log(e,item)
				item.entity_name=e.target.value
			},
			nameBlur2(e,value,item){
				item.spouse_name=e.target.value
			},
			nameBlur3(e,value,item){
				item.name=e.target.value
			},
			changeShareInfo(e,value,shareInfo){
			},
			toEntity(item){
				this.$emit("toentity", item)
			},
			  querySearch(queryString, cb) {
			        // 调用 callback 返回建议列表的数据
			        cb(this.list);
			      },
			querySearch2(queryString, cb) {
			      // 调用 callback 返回建议列表的数据
				  let temp =[]
				  let that = this
				  this.list.map(res=>{
					  if(res.entity_type_id!=2&&res.value!=that.main_data.entity_name){
						  temp.push(res)
					  }
				  })
			      cb(temp);
			    },
			toEntity1(item){
				let temp = {
					target:{
						entity_name:item
					}
				}
				this.$emit("toentity", temp)
			},
			scrollToMy(position){
				if(position){
					setTimeout(()=>{
						this.$refs[position+'_entity'].scrollIntoView({ behavior: 'smooth'});
					},1000)
				}
				
			},
			checkRatio(index){
				
				if (this.main_data.entity_type_id == 1 || this.main_data.entity_type_id == 5) {
					let percent = 0
					let hasItem = false
					this.main_data.share_info.map(res => {
						if (res.entity_type_id != '' || res.entity_name != '') {
							hasItem = true
							percent += parseFloat(res.share_ratio ? res.share_ratio : 0)
						}
						
					})
					percent = Math.round(percent * 10000000) / 10000000
					this.main_data.share_info_true = true
					if (hasItem) {
						if (percent > 100) {
							this.main_data.share_info[index].share_ratio = ''
							let msg =this.main_data.entity_type_id == 1? this.QWER(`股持股比例为xxxxxx%,超出100%,请重新填写！`,percent):this.QWER(`合伙人份额为xxxxxx%,超出100%,请重新填写！`,percent);
							
							this.$alert(msg, this.QWER('提示'), {
							          confirmButtonText: this.QWER('确定'),
							          callback: action => { }
							});
							
							
						}
					}
					
				}
			},
			handleInput(value) {
				let res = value
				if (Number(value) > 100) {
					res = 100
				}
				if (Number(value) < 0) {
					res = 0
				}
				return res; // 只保留数字
			},
			handleInputNumber(value) {
				if (parseFloat(value) < 0) {
					return ''
				}
				return value.replace("e", '')
			},
			changeRelend() {
				let shouru = 0
				this.main_data.income_info.map(res=>{
					if(res.income_id!=''){
						shouru+=Number(res.total) 
					}
				})

				let fuzhai = 0
				this.main_data.debt_info.map(debt => {
					if (debt.is_relend == 1) {
						fuzhai += Number(debt.total)
					}
				})
				if (fuzhai > 0 && shouru <= 0) {
					return true
				} else {
					return false
				}
			},
			changeRelend2() {
				let shouru = 0
				this.main_data.income_info.map(res=>{
					if(res.income_id!=''){
						shouru+=Number(res.total) 
					}
				})
				if (this.main_data.entity_type_id == 6) { //个人联名
					shouru = 10
				}
				let fuzhai = 0
				this.main_data.debt_info.map(debt => {
					fuzhai += Number(debt.total)
				})
				if (fuzhai > 0 && shouru <= 0) {
					return true
				} else {
					return false
				}
			},
			getInitData() {
				getLoanPurposes({
					level: 1
				}).then(res => {
					this.loanTypeFuzhai = res.data
				})
				getLoanPurposes({
					level: ''
				}).then(res => {
					this.loanTypeDaikuang = res.data
				})
				getPawnType().then(res => {
					this.pawnTypeList = res.data
				})
				getIncomeList({type:1}).then(res=>{
					this.incomeList1 =res.data
				})
				getIncomeList({type:2}).then(res=>{
					this.incomeList2 =res.data
				})
			},
			changeEntityType(item, val) {
				if (val == 3 || val == 4 || val == 6) {
					item.share_ratio = 100
				} else {
					item.share_ratio = ''
				}
			},
			save(val) { //保存
				let hasInput= false
				if(val==99){
					hasInput= true
				}
				if (this.is_main != 1) {
					this.main_data.new_pawn_info = [this.tempNewZichanData]
				}
				//是否有负债无收入
				this.main_data.hasFuzhai = this.changeRelend2()
				let templist = []
				this.main_data.share_other_info.map(res => {
					let hasRes = false
					templist.map(temp => {
						if(!temp.share_ratio) {
							temp.share_ratio= 0
						}
						if (temp.entity_names == res.entity_names && temp.entity_name == res.entity_name &&
							res.entity_type_id == temp.entity_type_id) {
							hasRes = true
							temp.share_ratio = parseFloat(temp.share_ratio ? temp.share_ratio : 0) +
								parseFloat(res.share_ratio ? res.share_ratio : 0)
						}
					})
					if (!hasRes) {
						if(res.entity_type_id==3||res.entity_type_id==4){
							res.share_ratio==100
						}
						templist.push(res)
					}
				})
				this.main_data.share_other_info = templist
				let msg  = ''
				if (this.main_data.entity_type_id == 1 || this.main_data.entity_type_id == 5) {
					let percent = 0
					let hasItem = false

					this.main_data.share_info.map(res => {
						if (res.entity_type_id != '' || res.entity_name != '') {
							hasItem = true
							percent += parseFloat(res.share_ratio ? res.share_ratio : 0)
						}
						// if(res.entity_type_id!=''&&res.entity_name!=''){
						// 	res.is_strong2=1
						// }
					})
					percent = Math.round(percent * 10000000) / 10000000
					this.main_data.share_info_true = true
					
					if (hasItem) {
						if (percent < 100) {
							 msg = `【${this.main_data.entity_name}】`+this.QWER('股东持股比例为xxxxxx%,不足100%,请仔细核对！',`${percent}`)
							
						}
						if (percent > 100) {
							 msg=  `【${this.main_data.entity_name}】`+this.QWER('股东持股比例为xxxxxx%,超过100%,请仔细核对！',`${percent}`)
							
						}
					}
				}
				if(val==2){
					msg=''
				}
				if(msg){
					this.$alert(msg, this.QWER('提示'), {
					          confirmButtonText: this.QWER('确定'),
					          callback: action => { 
								  this.$refs['ruleForm'].validate((valid) => {
								  	if (valid) {
								  		let temp = {
								  			type: 'xinxi',
								  			has: hasInput,
								  			val: val,
								  			data: this.main_data
								  		}
								  		this.$emit("save", temp)
								  	} else {
								  		let temp = {
								  			type: 'xinxi',
								  			has: false,
								  			val: val,
								  			data: this.main_data
								  		}
								  		this.$emit("save", temp)
								  	}
								  });
							  }
					});
				}else{
					let temp = {
						type: 'xinxi',
						has: hasInput,
						val: val,
						data: this.main_data
					}
					this.$emit("save", temp)
				}
			},
			clearitem(item){
				Object.keys(item).map(res=>{
					item[res] = ''
				})
			},
			clearitem2(item,index){
				Object.keys(this.main_data.debt_info[index]).map(res=>{
					item[res] = ''
				})
				this.main_data.debt_info[index].is_relend = 0
				Object.keys(this.main_data.pawn_info[index]).map(res=>{
					item[res] = ''
				})
				this.main_data.pawn_info[index].is_relend = 1
			},
			addDirectorInfo() { //添加董事信息
				this.main_data.director_info_list.push({
					name: ''
				})
			},
			delDirectorInfo(index) { //删除董事信息
			this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
			          confirmButtonText: this.QWER('确定'),
			          cancelButtonText: this.QWER('取消'),
			          type: 'warning'
			        }).then(() => {
			          this.main_data.director_info_list.splice(index, 1)
			        }).catch(() => {
			                   
			        });
				
			},
			addShareInfo() { //添加股东信息
				let temp = JSON.parse(JSON.stringify(this.shareInfoTemp))
				if (this.main_data.entity_type_id == 6) {
					temp.entity_type_id = 2
				}
				this.main_data.share_info.push(temp)
			},
			delShareInfo(index) { //删除股东信息
				
				this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
				         this.main_data.share_info.splice(index, 1)
				        }).catch(() => {
				                   
				        });
			},
			addShouRuitem() { //新增
				let temp = JSON.parse(JSON.stringify(this.incomeTemp))
				this.main_data.income_info.push(temp)
			},
			deleteShouRuItem11(index) {
				console.log(index)
				this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
				          this.main_data.income_info.splice(index, 1)
				        }).catch(() => {
				                   
				        });
				
			},
			addFuzhaiitem() { //新增
				let temp1 = JSON.parse(JSON.stringify(this.tempFuzhaiData))
				this.main_data.debt_info.push(temp1)
				let temp2 = JSON.parse(JSON.stringify(this.tempZichanData))
				this.main_data.pawn_info.push(temp2)
			},
			deleteFuzhaiItem(index) {
				this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
				          this.main_data.debt_info.splice(index, 1)
						  this.main_data.pawn_info.splice(index, 1)
				        }).catch(() => {
				                   
				        });
				
			},
			addZichanitem() { //新增
				let temp1 = JSON.parse(JSON.stringify(this.tempFuzhaiData))
				this.main_data.debt_info.push(temp1)
				let temp2 = JSON.parse(JSON.stringify(this.tempZichanData))
				this.main_data.pawn_info.push(temp2)
			},
			deleteZichanItem(index) {
				
				this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
				          this.main_data.pawn_info.splice(index, 1)
						   this.main_data.debt_info.splice(index, 1)
				        }).catch(() => {
				                   
				        });
			},
			addNewZichanitem() { //新增
				let temp = JSON.parse(JSON.stringify(this.tempNewZichanData))
				this.main_data.new_pawn_info.push(temp)
			},
			deleteNewZichanItem(index) {
				this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
				          this.main_data.new_pawn_info.splice(index, 1)
				        }).catch(() => {
				                   
				        });
				
			},
			getShareOtherInfoList() {
				let result = []
				this.main_data.director_info_list.map(res => {
					if (res.name != '' && !result.includes(res.name)) {
						result.push(res.name)
					}
				})
				this.main_data.share_info.map(res => {
					if (res.entity_name != '' && !result.includes(res.entity_name)) {
						result.push(res.entity_name)
					}

				})
				return result
			},
			addShareOtherInfo() { //添加 股东及董事持有的其他实体（持股比例大于%25）
				let temp = JSON.parse(JSON.stringify(this.shareOtherInfoTemp))
				this.main_data.share_other_info.push(temp)
			},
			delShareOtherInfo(index) { //删除 股东及董事持有的其他实体（持股比例大于%25）
			this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
			          confirmButtonText: this.QWER('确定'),
			          cancelButtonText: this.QWER('取消'),
			          type: 'warning'
			        }).then(() => {
			          this.main_data.share_other_info.splice(index, 1)
			        }).catch(() => {
			                   
			        });
				
			},

			addEntityOtherInfo() { //添加 实体名称A持有的其他实体（持股比例大于25%）
				let temp = JSON.parse(JSON.stringify(this.entityOtherInfoTemp))
				this.main_data.entity_other_info.push(temp)
			},
			delEntityOtherInfo(index) { //删除 实体名称A持有的其他实体（持股比例大于25%）
				
				this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
				          this.main_data.entity_other_info.splice(index, 1)
				        }).catch(() => {
				                   
				        });
			},
			addBenefInfo() { //添加 实体名称A持有的其他实体（持股比例大于25%）
				let temp = JSON.parse(JSON.stringify(this.benefInfoTemp))
				this.main_data.benef_info.push(temp)
			},
			delBenefInfo(index) { //删除 实体名称A持有的其他实体（持股比例大于25%）
				
				this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
				          this.main_data.benef_info.splice(index, 1)
				        }).catch(() => {
				                   
				        });
			},
			getBenefInfoList() { //受益人列表
				let result = []
				this.main_data.benef_info.map(res => {
					if (res.entity_name != '' && !result.includes(res.entity_name)) {
						result.push(res.entity_name)
					}

				})
				return result
			},


		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .el-form-item {
		margin-bottom: 8px;
	}
	::v-deep .el-form--label-top .el-form-item__label{
		padding-bottom: 0px;
		line-height: 30px;
	}
	.title_main {
		font-weight: bold;
		font-size: 36px;
		color: #000000;
		line-height: 36px;
	}

	.tips_main {
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		line-height: 20px;
		margin-top: 21px;
	}

	.title_second {
		color: #333;
		font-weight: bold;
		margin-bottom: 6px;
		margin-top: 10px;
	}
	.text-out1{
		font-size: 14px;
		color: #DE7C2B;
		padding-left: 20px;
		padding-bottom: 20px;
		position: relative;
		margin-top: -10px;
		cursor: pointer;
	}
	.text-out{
		font-size: 14px;
		color: #ffffff;
		padding-left: 20px;
		padding-bottom: 20px;
		position: relative;
		margin-top: -10px;
		cursor: pointer;
		i{
			color: #000000;
		}
	}
	.text-out:hover{
		color: #DE7C2B;
	}
	
</style>