<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	export default {

	}
</script>
<style lang="scss">
	* {
		margin: 0;
		padding: 0;
		list-style: none;
		text-decoration: none;
	}
	
	/* 滚动条整体部分,必须要设置 */
	::-webkit-scrollbar {
	  width: 8px;
	  height: 8px;
	  background-color: #f5f6fa;
	}
	
	/* 滚动条的轨道 */
	::-webkit-scrollbar-track {
	  width: 8px;
	  height: 8px;
	}
	
	/* 滚动条的滑块按钮 */
	::-webkit-scrollbar-thumb {
	  width: 6px;
	  height: 6px;
	  border-radius: 4px;
	  background-color: #b6bece;
	  cursor: pointer;
	}
	
	/* 滚动条的上下两端的按钮 */
	::-webkit-scrollbar-button {
	  height: 6px;
	  width: 0;
	}
	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.flex-col {
		display: flex;
		flex-direction: column;
	}

	.flex-1 {
		flex: 1;
	}

	.lease-btn {
		width: 40px;
		height: 40px;
		cursor: pointer;
		background: #000000;
		color: #ffffff;
	}

	.el-popper[x-placement^="bottom"] {
		border: 1px solid #797979;
		border-radius: 0;

		.desc {
			margin-bottom: 20px;
		}

		.sign-out {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			width: 73px;
			height: 30px;
			border-radius: 3px;
			background-color: #de7c2b;
			font-size: 13px;
			margin-top: 20px;
		}

		.path-list {
			.active {
				color: #de7c2b;
			}

			li {
				cursor: pointer;
				transition: 0.3s;

				p {
					height: 40px;
					display: flex;
					align-items: center;
					// justify-content: center;
				}

				&:hover {
					color: #de7c2b;
				}
			}
		}
	}

	.el-dropdown-menu {
		border: none !important;
		box-shadow: none !important;
	}

	.popper__arrow {
		display: none !important;
	}

	#nav {
		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
	.el-input__inner{
		padding: 0 5px !important;
	}
</style>