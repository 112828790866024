<template>
  <div>
    <el-dialog :title="QWER('提问详情')" :visible.sync="dialogVisible" :show-close="true" :close-on-click-modal='false'
               width="1200px" style="border-radius: 20px;margin-top: -8vh">
      <div>
        <div>
          <div class="title-text">{{ detail.title }}</div>
          <div class="content-text">{{ detail.contents }}</div>
          <div style="display: flex;margin-top: 20px">
            <el-image
                style="width: 75px; height: 75px;margin-right: 8px;border-radius: 4px;"
                :src="item"
                :preview-src-list="[item]" v-for='(item,index) in getImageList(detail.annexs)'>
            </el-image>
          </div>
          <div class="title-text" style="margin-top: 20px">{{ QWER('回复信息：') }}</div>
          <div class="content-text">{{ detail.reply_contents }}</div>

        </div>
      </div>
      <div style="display: flex;margin-top: 20px">
        <div style="flex: 1;font-weight: bold;font-size: 20px;color: #333333;line-height: 48px;">
          {{ QWER('追问信息') }}（{{ totalNum }}）
        </div>
        <el-button type="warning" @click="openAdd">{{ QWER('追问') }}</el-button>
        
      </div>
      <el-table :data="tabledata" style="width: 100%;margin-top: 20px;" height="300">
        <el-table-column :label="QWER('序号')" type="index">
        </el-table-column>

        <el-table-column prop="contents" :label="QWER('追问内容')">
          <template #default="{ row }">
            <div class="text-ellipsis">{{ row.contents }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="annexs" :label="QWER('追问图片')">
          <template #default="{ row }">
            <div style="display: flex;">
              <el-image
                  style="width: 50px; height: 50px;margin-right: 8px;border-radius: 4px;"
                  :src="item"
                  :preview-src-list="[item]" v-for='(item,index) in getImageList(row.annexs)'>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="QWER('回复状态')" width="100">
          <template #default="{ row }">
            <div style="color: #FF3737;" v-if="row.status==0"> {{QWER('待回复')}}</div>
            <div style="color: #28C445;" v-if="row.status==1"> {{QWER('已回复')}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="contents" :label="QWER('回复内容')">
          <template #default="{ row }">
            <div class="text-ellipsis">{{ row.reply_contents }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right;padding-top: 10px;display: flex;">
		  <el-button type="info" @click="close">back</el-button>
        <el-pagination @current-change="changePage" background layout="prev, pager, next" :current-page="page"
                       :total="total" style="flex: 1;">
        </el-pagination>
      </div>
    </el-dialog>
    <addMoreAsk ref='addMoreAsk' @success="updateData"></addMoreAsk>
  </div>

</template>

<script>
import {
  getLeaveRecords
} from "@/api/loans/loans"
import addMoreAsk from "@/views/ask/addMoreAsk.vue";
import {getUserInfo} from "@/utils/myAuth";

export default {
  components: {
    addMoreAsk
  },
  data() {
    return {
      dialogVisible: false,
      page: 1,
      tabledata: [],
      total: 1,
      detail: {},
      userInfo:'',
      totalNum:0,
    };
  },
  created() {
  },
  methods: {
    QWER(message) {
      let temp = this.$t(message)
      return temp
    },
    open(detail) {
      this.detail = detail
      this.tabledata=[]
      this.dialogVisible = true
      this.page = 1
      this.getData()
    },
    openAdd() {
      this.$refs.addMoreAsk.open(this.detail)
    },
	close(){
		this.dialogVisible = false
	},
    getImageList(item) {
      if (item) {
        return item.split(',')
      } else {
        return []
      }
    },
    updateData(){
      this.page = 1
      this.getData()
    },
    getData() {
      const loading = this.$loading()
      getLeaveRecords({
        parent_id:this.detail.id,
        page: this.page,
        pageSize:10,
        keyword:'',
      }).then(res => {
        console.log(res)
        loading.close()
        if (res.code == 200) {
          this.tabledata = res.data.data
          this.total = res.data.total
          this.totalNum =res.data.total
        } else {

        }
      }).catch(err => {
        loading.close()
      })
    },
    changePage(page) {
      this.page = page
      this.getData()
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__title {
    font-weight: bold;
    font-size: 30px;
    color: #000000;
    line-height: 20px;
  }

  .el-dialog__headerbtn .el-dialog__close {
    font-size: 24px;
  }

  .el-table th.el-table__cell {
    background-color: #000000;
    color: #fff;
  }
}

.title-text {
  font-weight: bold;
  font-size: 20px;
  color: #333333;
}

.content-text {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-top: 10px;
}
</style>