<template>
  <div class="connect">
    <div class="heart">
      <div class="info">
        <div class="info-item">
          <div class="desc">
            <span>Address:</span>
            <p>Suite 5.05, Level 5,</p>
          </div>
          <div class="desc right">
            <span>Phone:</span>
            <p>(02) 8591 0817</p>
          </div>
        </div>
        <div class="info-item">
          <div class="desc">
            <p>12 O’Connell St Sydney NSW 2000</p>
          </div>
          <div class="desc right">
            <span>Email:</span>
            <p>info@reginsun.com</p>
          </div>
        </div>
        <div class="info-item">
          <div class="desc">
            <p class="bottom">
              © 2016-2019 All Rights Reserved by Reginsun Group ACL 445579 AFSL
              448540
            </p>
          </div>
        </div>
      </div>
      <div class="search">
        <div class="search-int">
          <el-input
            placeholder="search"
            prefix-icon="el-icon-search"
            v-model="keywords"
            @change="jump(keywords)"
          >
          </el-input>
        </div>
        <div class="search-link">
          <p
            @click="jumpNav(`https://reginsun.com/privacy-policy`)"
            class="first"
          >
            Privacy Policy
          </p>
          <span>|</span>
          <p @click="jumpNav(`https://reginsun.com/contact-us`)">Contact</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keywords: "",
    };
  },
  methods: {
    jump(keywords) {
      window.open(`https://reginsun.com/search?q=${keywords}`);
    },
    jumpNav(url) {
      console.log(url);
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/baseScss/baseScss.scss";
// 我们的信息
.connect {
  min-width: 1080px;
  margin: 64px 0;
  .heart {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    font-weight: 300;
    .info {
      flex: 1;
      .info-item {
        @include between;
        //   display: flex;

        margin-bottom: 20px;
        .desc {
          @include between;
          .bottom {
            padding-left: 40px;
          }
          span {
            // color: rgba(0, 0, 0, 0.4);
            font-weight: 700;
            margin-right: 10px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 300px;
        }
      }
    }
    .search {
      width: 300px;
      .search-link {
        margin-top: 20px;
        display: flex;
        .first {
          margin-left: 50px;
        }
        p {
          cursor: pointer;
          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
          //   margin-right: 50px;
          line-height: 30px;
        }
        span {
          margin: 0 20px;
          display: flex;
          align-items: center;
        }
      }
      ::v-deep.el-input__inner:focus {
        border-color: #333;
      }
      ::v-deep .el-input__inner {
        padding: 0 30px !important;
      }
    }
  }
}
.mask {
  @include center;
  background-color: rgba(31, 37, 38, 0.35);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff !important;
  color: $color;
}
</style>
