/*
 * @Author: LegerVeilchen LegerVeilchen@foxmail.com
 * @Date: 2022-06-06 19:45:08
 * @LastEditors: LegerVeilchen LegerVeilchen@foxmail.com
 * @LastEditTime: 2022-06-14 16:54:42
 * @FilePath: \loan-web\src\permission.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import router from "./router";
import {
  getToken
} from "@/utils/myAuth";
const whiteList = [ "/success","/index","/login","/home",'relation']; 
const blackList = ["/success"]; // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  const hasToken = getToken();
  if (hasToken) {
      next();
  } else {
    if (whiteList.indexOf(to.path) !== -1||to.path=='/') {
      next();
    } else {
      next(`/login`);
    }
  }
});